import { createSlice, PayloadAction } from 'redux-starter-kit';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { AssignmentApplicationResult } from '@/types/models/assignment_application_result';

export interface AssignmentApplicationResultState {
  pristine: boolean;
  result: AssignmentApplicationResult | false;
}

const initialState: AssignmentApplicationResultState = {
  pristine: true,
  result: false,
};

const assignmentApplicationResultModule = createSlice({
  slice: 'assignment_application_result',
  initialState,
  reducers: {
    initialize(state, value: PayloadAction<AssignmentApplicationResult>) {
      state.pristine = false;
      state.result = value.payload;
    },
  },
});

export const useAssignmentApplicationResult = () => {
  return useSelector((state: AppState) => state.assignmentApplicationResult);
};

export default assignmentApplicationResultModule;

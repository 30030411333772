import React, { Dispatch, SetStateAction } from 'react';

import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { usePortal } from '@/helpers/hooks';

const Wrapper = styled.div`
  & > input {
    display: none;
  }
  .overlay {
    display: flex;
    justify-content: center;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.5s, transform 0s 0.5s;
    transform: scale(0);
    z-index: 9999;
  }
  input:checked ~ .overlay {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s;
  }
  .modal_trigger {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

interface ModalBodyProps {
  isOpen: boolean;
}

export interface ModalState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export function wrapModalState(modalState: [boolean, Dispatch<SetStateAction<boolean>>]) {
  const [isOpen, updateIsOpen] = modalState;
  const open = () => updateIsOpen(true);
  const close = () => updateIsOpen(false);
  return {
    isOpen,
    open,
    close,
  };
}

const ModalBody = styled.div`
  position: relative;
  align-self: center;
  width: 94%;
  max-width: 480px;
  padding: 32px 24px 32px;
  box-sizing: border-box;
  background: #fff;
  line-height: 1.4em;
  transition: 0.5s;
  transform: ${(props: ModalBodyProps) => (props.isOpen ? 'translateY(20px)' : 'translateY(100%)')};
`;

const CloseButton = styled.label`
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  cursor: pointer;
`;

interface Props {
  id?: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

function ModalComponent(props: Props) {
  return (
    <Wrapper>
      <input id="trigger" type="checkbox" checked={props.isOpen} onChange={props.onClose} />
      <div className="overlay">
        <label htmlFor="trigger" className="modal_trigger" />
        <ModalBody isOpen={props.isOpen}>
          <CloseButton htmlFor="trigger">
            <i className="material-icons-outlined md-36">close</i>
          </CloseButton>
          {props.children}
        </ModalBody>
      </div>
    </Wrapper>
  );
}

export default function Modal(props: Props) {
  const portal = usePortal(props.id || 'modal');
  return createPortal(<ModalComponent {...props} />, portal);
}

import React from 'react';
import styled from 'styled-components';

const List = styled.li`
  list-style-type: none;
  display: inline-block;
  position: relative;
  span {
    text-align: left;
    padding-left: 32px;
    display: inline-block;
    > * {
      display: inline-block;
    }
  }
  img {
    display: block;
    width: 18px;
    max-height: 20px;
    position: absolute;
    left: 0;
    top: 3px;
  }
`;

type Props = {
  emoji: string;
  children: React.ReactNode;
};

export default function ListItemEmoji(props: Props) {
  return (
    <List>
      <img src={props.emoji} alt={props.emoji} />
      <span>{props.children}</span>
    </List>
  );
}

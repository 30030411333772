import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';

const Headline = styled.p`
  color: ${Color.secondary};
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.04em;
  white-space: pre-line;
  margin: 0;
  padding: 0;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.success {
    color: ${Color.success};
  }
  &.danger {
    color: ${Color.danger};
  }
`;

type Props = {
  title?: string;
  align?: string;
  theme?: string;
  children?: React.ReactNode;
};

H3.defaultProps = {
  align: 'left',
  theme: 'secondary',
};

export default function H3(props: Props) {
  return (
    <Headline className={`h3 ${props.align} ${props.theme}`}>
      {props.title}
      {props.children}
    </Headline>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';

const Wrapper = styled.div`
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const Title = styled.p`
  width: 50%;
  font-size: 15px;
  font-weight: bold;
  ${(props: Props) => props.score / props.cap < 0.5 && `color: ${Color.danger}`}
`;

const Progress = styled.div`
  width: 120px;
  height: 6px;
  border-radius: 8px;
  position: relative;
  background: ${Color.shadow2};
  overflow: hidden;
  &::before {
    content: '';
    width: ${(props: Props) => Math.floor((props.score / props.cap) * 100)}%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    ${(props: Props) =>
      props.score / props.cap < 0.5 ? `background: ${Color.danger};` : `background: ${Color.success};`}
  }
`;

const Count = styled.p`
  width: 60px;
  text-align: right;
  padding-left: 4px;
`;

type Props = {
  scene: string;
  score: number;
  cap: number;
};

export default function Component(props: Props) {
  return (
    <Wrapper>
      <Title scene={props.scene} score={props.score} cap={props.cap}>
        {props.scene}
      </Title>
      <Progress scene={props.scene} score={props.score} cap={props.cap} />
      <Count>
        {props.score} / {props.cap}
      </Count>
    </Wrapper>
  );
}

import styled from 'styled-components';
import { Color } from '@/constants/Color';
import { ValidAnswersState } from '@/pages/Questions/helper';
import { AnswerManager } from '@/helpers/answer';
import { RouteComponentProps } from 'react-router';
import { ResearchAnswer } from '@/types/models/research_answer';
import { ResearchQuestion } from '@/types/models/research_question';

export interface Props {
  order: number;
  question: ResearchQuestion;
  validAnswersState: ValidAnswersState;
  visibleQuestionIds: number[];
}

export type ChildProps = Props & {
  answer: ResearchAnswer;
  onChange: (value: ResearchAnswer) => void;
};

export const QuestionCard = styled.div`
  padding: 46px 36px;
  background: #fff;
  box-sizing: border-box;
  border-bottom: solid 8px #E7F0EF;
  .h2 {
    padding-bottom: 8px;
  }
  select, input[type=time] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    &::-ms-expand {
      display: none;
    }
  }
  input, select, textarea, button, label {
    width: 100%;
    max-width: 100%;
    min-height: 52px;
    box-sizing: border-box;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
    border: solid 1px ${Color.shadow2};
    background: ${Color.shadow};
    outline: none;
    font-size: 15px;
    cursor: pointer;
  }
  input[type=time] {
    text-align: center;
    font-size: 16px;
  }
  textarea {
    resize: vertical;
    font-size: 16px;
    line-height: 1.6148;
  } 
  input.selected, select.selected, textarea.selected, button.selected, label.selected {
    background: ${Color.white}
    border: solid 2px ${Color.success};
  }
  input:focus, select:focus, textarea:focus, button:focus, label:focus {
    background: ${Color.white};
  }
  input.error, select.error, textarea.error, button.error, label.error {
    background: ${Color.white}
    border: solid 2px ${Color.danger};
  }
`;

const singleActionQuestionTypes = ['dropdown', 'image', 'horizontal-buttons', 'vertical-buttons'];

export function trimSpaces(str: string): string {
  return str
    .replace(/　/g, ' ')
    .split(' ')
    .join('');
}

function validateStaffAnswer(answer: ResearchAnswer): boolean {
  if (!answer.value) {
    return false;
  }
  if (answer.value === '0' && (!answer.comment || trimSpaces(answer.comment).length === 0)) {
    return false;
  }
  return !!answer.value;
}

function validate(answer: ResearchAnswer, question: ResearchQuestion): boolean {
  if (question.type === 'staff') {
    return validateStaffAnswer(answer);
  }
  if (question.minlength === undefined) {
    return !!answer.value;
  }
  return trimSpaces(answer.value).length >= question.minlength;
}

function updateValidAnswerState(isValid: boolean, question: ResearchQuestion, state: ValidAnswersState) {
  const next = state.questionIds.filter(qId => qId !== question.id);
  if (isValid) {
    next.push(question.id);
  }
  state.update(next);
}

function isSingleActionQuestion(
  questionType: 'input' | 'radio' | 'dropdown' | 'image' | 'vertical-buttons' | 'horizontal-buttons' | 'time' | 'staff' | 'date'
) {
  return singleActionQuestionTypes.includes(questionType);
}

function scrollToNextQuestion(currentQuestion: ResearchQuestion, router: RouteComponentProps) {
  const hash = currentQuestion.nextQuestionId ? `question_${currentQuestion.nextQuestionId}` : 'submit';
  const path = router.location.pathname;
  router.history.push(`${path}#${hash}`);
}

export function curriedOnChange(
  props: Props,
  updateValue: (answer: ResearchAnswer) => void,
  router: RouteComponentProps
) {
  return (answer: ResearchAnswer) => {
    updateValue(answer);
    updateValidAnswerState(validate(answer, props.question), props.question, props.validAnswersState);
    AnswerManager.save(answer);
    if (!isSingleActionQuestion(props.question.type)) {
      return;
    }
    if (!answer.value) {
      return;
    }
    scrollToNextQuestion(props.question, router);
  };
}

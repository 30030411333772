import { fetchResearchReport, approveResearchReport } from "@/api/report";
import {ResearchQuestion} from "@/types/models/research_question";
import {ResearchAnswer} from "@/types/models/research_answer";
import {ResearchQuestionExperience} from "@/types/models/research_question_experience";
import reportModule, {ReportState} from "@/stores/report";
import {RouteComponentProps} from "react-router";
import {parse} from "querystring";
import {Dispatch as HooksDispatch, SetStateAction} from "react";
import {Dispatch} from "redux";
import {ResearchReport} from "@/types/models/research_report";

interface QueryProps {
    researcherId: string;
}

function parseQueries(router: RouteComponentProps): QueryProps {
    const queries = parse(router.location.search.replace('?', ''));
    return {
        researcherId: typeof queries.id === 'string' ? queries.id : "",
    };
}

interface PrepareData {
    answers: { [key: number]: ResearchAnswer };
    experiences: { [key: number]: ResearchQuestionExperience };
    score: number;
}

const sum = (a = 0, b = 0) => (a + b);

function prepareData(
    initAnswers: ResearchAnswer[],
    initQuestions: ResearchQuestion[],
    initExperiences: ResearchQuestionExperience[],
): PrepareData {
    let answers: { [key: number]: ResearchAnswer } = {},
        questions: { [key: number]: ResearchQuestion } = {},
        experiences: { [key: number]: ResearchQuestionExperience} = {},
        score = 0;

    initQuestions.forEach(question => {
        questions[question.id] = question;
    });

    initExperiences.forEach(experience =>{
        experiences[experience.id] = {...experience};
    });

    for (let i = 0; i < initAnswers.length; i++) {
        let ar = {...initAnswers[i]};
        let qid = ar.question_id;

        let choices = questions[qid].choices;
        if (choices) {
            let eid = questions[qid]['experience_id'];
            choices.forEach(c => {
                if (eid) {
                    experiences[eid].cap = sum(experiences[eid].cap, c.score);
                }
                ar.cap = sum(ar.cap, c.score);
                if (ar.value === c.value) {
                    ar.value = c.label;
                    ar.score = c.score;

                    if (eid) {
                        experiences[eid].score =  sum(experiences[eid].score, c.score);
                        score = sum(score, c.score);
                    }
                }
            });

            if (ar.cap && ar.cap > 0 && !ar.score) {
                ar.score = 0;
            }
        }
        answers[qid] = ar;
    }

    return {
        answers: answers,
        experiences: experiences,
        score: score,
    };
}

interface ReportHelper {
    report: ResearchReport | false;
    experiences: {[key:number]: ResearchQuestionExperience}|undefined;
    answers: {[key:number]: ResearchAnswer}|undefined;
    score: number;
    initialize: () => void;
    initializing: boolean;
}

export default function makeReportHelper(
    router: RouteComponentProps,
    dispatch: Dispatch,
    report: ReportState,
    initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>],
    experiencesState: [{[key:number]:ResearchQuestionExperience}|undefined, HooksDispatch<SetStateAction<{[key:number]:ResearchQuestionExperience}|undefined>>],
    answersState: [{[key:number]:ResearchAnswer}|undefined, HooksDispatch<SetStateAction<{[key:number]:ResearchAnswer}|undefined>>],
    scoreState: [number, HooksDispatch<SetStateAction<number>>]
): ReportHelper {
    const queries = parseQueries(router);
    const [initializing, setInitializing] = initializingState;
    const [experiences, setExperiences] = experiencesState;
    const [answers, setAnswers] = answersState;
    const [score, setScore] = scoreState;
    const result = report.result;

    const initialize = () => {
        if (!initializing && result) {
            return;
        }
        setInitializing(false);
        (async () => {
            const res = await fetchResearchReport(queries.researcherId);
            dispatch(reportModule.actions.initialize(res));

            if (!res.questions || !res.experiences) {
                return;
            }

            const data = prepareData(res.answers, res.questions, res.experiences);
            setAnswers(data.answers);
            setExperiences(data.experiences);
            setScore(data.score);
        })();
    };

    return {
        report: result,
        experiences: experiences,
        answers: answers,
        score: score,
        initialize,
        initializing: initializing,
    };
}
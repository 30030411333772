import { Dispatch as HooksDispatch, SetStateAction } from 'react';
import { AnswerManager } from '@/helpers/answer';
import { RouteComponentProps } from 'react-router';
import researchModule, { ResearchState } from '@/stores/research';
import { Dispatch } from 'redux';
import { ResearcherManager } from '@/helpers/researcher';
import { fetchResearch } from '@/api/research';
import { ResearchQuestion } from '@/types/models/research_question';
import { calcProgress, calcRemain, rehydrate, wrapValidAnswerState } from '@/helpers/research';

export interface ValidAnswersState {
  questionIds: number[];
  update: (questionIds: number[]) => void;
}

function getVisibleQuestionIds(questions: ResearchQuestion[], validAnswerState: ValidAnswersState): number[] {
  if (questions.length === 0) {
    return [];
  }
  const answeredQuestionIds = validAnswerState.questionIds;
  if (answeredQuestionIds.length === 0) {
    return [questions[0].id];
  }
  const visibleQuestionIds = new Set<number>(answeredQuestionIds);
  questions.forEach(q => {
    if (!q.nextQuestionId) {
      return;
    }
    if (!answeredQuestionIds.includes(q.id)) {
      return;
    }
    visibleQuestionIds.add(q.nextQuestionId);
  });
  AnswerManager.all().forEach(answer => visibleQuestionIds.add(answer.question_id));
  return Array.from(visibleQuestionIds);
}

function curriedInitialize(
  researcherId: string,
  research: ResearchState,
  dispatch: Dispatch,
  validAnswerState: ValidAnswersState,
  initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>]
) {
  return () => {
    const [initializing, setInitializing] = initializingState;
    if (!initializing) {
      return;
    }
    setInitializing(false);
    (async () => {
      const researcher = ResearcherManager.get(researcherId);
      if (!researcher) {
        return;
      }
      const res = await fetchResearch(researcher.research_id);
      dispatch(researchModule.actions.initialize(res));
    })();
    validAnswerState.update([]);
    const valid = new Set<number>();
    AnswerManager.all().forEach(answer => {
      if (!answer.value) {
        return;
      }
      valid.add(answer.question_id);
    });
    validAnswerState.update(Array.from(valid));
  };
}

interface QuestionsHelper {
  researcherId: string;
  initializing: boolean;
  initialize: () => void;
  validAnswerState: ValidAnswersState;
  progress: number;
  remain: number;
  submittable: boolean;
  handleClickSubmit: () => void;
  questions: ResearchQuestion[];
  visibleQuestionIds: number[];
}

export interface QuestionsRouteProps {
  id: string;
}

export default function makeQuestionsHelper(
  router: RouteComponentProps<QuestionsRouteProps>,
  dispatch: Dispatch,
  research: ResearchState,
  validAnswerState: [number[], HooksDispatch<SetStateAction<number[]>>],
  initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>]
): QuestionsHelper {
  const researcherId = router.match.params.id;
  const [initializing] = initializingState;
  const wrappedValidAnswerState = wrapValidAnswerState(validAnswerState);
  const initialize = curriedInitialize(researcherId, research, dispatch, wrappedValidAnswerState, initializingState);
  const handleClickSubmit = () => router.history.push(`/completed/${researcherId}`);
  const progress = calcProgress(wrappedValidAnswerState, research);
  const remain = calcRemain(wrappedValidAnswerState, research);
  const submittable = remain === 0;
  const questions = research.result ? rehydrate(research.result.questions) : [];
  const visibleQuestionIds = getVisibleQuestionIds(questions, wrappedValidAnswerState);
  return {
    researcherId,
    initializing,
    initialize,
    validAnswerState: wrappedValidAnswerState,
    handleClickSubmit,
    progress,
    remain,
    submittable,
    questions,
    visibleQuestionIds,
  };
}

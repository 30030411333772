import React from 'react';

import { ChildProps, QuestionCard } from '@/components/Questions/Question/helper';
import styled from 'styled-components';
import H2 from '@/components//Common/H2';

interface ButtonProps {
  active: boolean;
}
const Button = styled.button`
  border: ${(props: ButtonProps) => (props.active ? '1px solid red' : '1px solid #000')};
  border-radius: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    flex: 0 0 calc(50% - 1px);
  }
  > *:first-child {
    border-radius: 7px 0 0 7px;
  }
  > *:last-child {
    border-radius: 0 7px 7px 0;
  }
`;

export default function ButtonsHorizontal(props: ChildProps) {
  const onClick = (value: string) =>
    props.onChange({
      ...props.answer,
      value,
    });
  return (
    <QuestionCard id={`question_${props.question.id}`}>
      <H2 title={`${props.order}.${props.question.title}`} />
      <Wrapper>
        {props.question.choices &&
          props.question.choices.map(c => (
            <Button
              key={`${props.question.id}_${c.value}`}
              active={c.value === props.answer.value}
              onClick={() => onClick(c.value)}
              className={c.value === props.answer.value ? 'selected' : ''}
            >
              {c.label}
            </Button>
          ))}
      </Wrapper>
    </QuestionCard>
  );
}

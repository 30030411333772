import { RouteComponentProps } from 'react-router';
import { ResearcherManager } from '@/helpers/researcher';
import { Restaurant } from '@/types/models/restaurant';
import { fetchAssignment } from "@/api/assignment";
import { fetchResearcher } from "@/api/researcher";
import { Dispatch as HooksDispatch, SetStateAction } from "react";

const researcherInit = (
    researcherId: string,
    initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>]
) => (() => {
  const [initializing, setInitializing] = initializingState;
  if (!initializing) {
    return;
  }
  if (ResearcherManager.get(researcherId)) {
    setInitializing(false);
    return;
  }
  (async () => {
    try {
      const researcher = await fetchResearcher(researcherId);
      const assignment = await fetchAssignment(researcherId);
      if (assignment.restaurant.name.indexOf(assignment.restaurant.brand_name) != -1) {
        assignment.restaurant.name = assignment.restaurant.name.replace(`${assignment.restaurant.brand_name} `, '');
        assignment.restaurant.name = assignment.restaurant.name.replace(`${assignment.restaurant.brand_name}　`, '');
        assignment.restaurant.name = assignment.restaurant.name.replace(assignment.restaurant.brand_name, '');
      }

      ResearcherManager.save({
        ...researcher,
        dueAt: researcher.due_at,
        isCompleted: researcher.report_completed_at !== undefined,
        lotteryResult: researcher.assigned_at !== undefined,
        restaurant: assignment.restaurant,
      });
    } finally {
      setInitializing(false);
    }
  })();
});

interface LotteryHelper {
  researcherId: string;
  isWon: boolean;
  goResume: () => void;
  restaurant: Restaurant | false;
  dueAt: string;
  initialize: () => void;
  initializing: boolean;
}

export type LotteryRouteProps = RouteComponentProps<{ id: string }>;

export default function makeLotteryHelper(
    router: LotteryRouteProps,
    initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>]
): LotteryHelper {
  const researcherId = router.match.params.id;
  const initialize = researcherInit(researcherId, initializingState);
  const [initializing, setInitializing] = initializingState;
  const goResume = () => router.history.push(`/resume/${researcherId}`);
  const isWon = ResearcherManager.isWon(researcherId);
  const restaurant = ResearcherManager.getRestaurant(researcherId);
  const dueAt = ResearcherManager.getDueAt(researcherId);
  return {
    researcherId,
    isWon,
    goResume,
    restaurant,
    dueAt,
    initialize,
    initializing,
  };
}

class LocalStorage {
  private key: string = 'hero-ms.ui';
  public get<T>(name: string): T {
    const item = this.getSavedItem();
    return item[name] as T;
  }
  public set(name: string, value: any): void {
    const saved = this.getSavedItem();
    localStorage.setItem(
      this.key,
      JSON.stringify({
        ...saved,
        [name]: value,
      })
    );
  }
  private getSavedItem(): any {
    const json = localStorage.getItem(this.key);
    return json ? JSON.parse(json) : {};
  }
}

export default new LocalStorage();

import React from 'react';
import styled from 'styled-components';
import { Color } from '../../constants/Color';

const Component = styled.button`
  width: 320px;
  min-width: 82%;
  max-width: 90%;
  padding: 1.1em;
  background: ${Color.secondary};
  color: ${Color.on_secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  border-radius: 2em;
  border: none;
  outline: none;
  margin-left: auto;
  margin-right: auto;
  transition: ease 60ms;
  &:hover {
    cursor: pointer;
    transform: scale(0.95) translateY(2px);
    color: ${Color.primary};
  }
  &:disabled {
    opacity: 0.2;
    &:hover {
      cursor: initial;
      transform: none;
      color: ${Color.on_secondary};
    }
  }
`;

type Props = {
  label: string;
  onClick: () => void;
};

export default function Button(props: Props) {
  return <Component onClick={props.onClick}>{props.label}</Component>;
}

import { Research } from '@/types/models/research';
import { ResearchReport } from '@/types/models/research_report';
import axios from '@/helpers/axios';

export async function fetchResearch(id: string) {
  return await axios.get<Research>(`/researches/${id}`).then(res => res.data);
}

export async function completeResearch(id: string, payload: ResearchReport) {
  return await axios.post<void>(`/researches/${id}/complete`, payload).then(res => res.data);
}

import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';

const Wrapper = styled.div`
  display: block;
  border: solid 2px ${Color.secondary};
  position: relative;
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;
  ${(props: Props) =>
    props.thin &&
    `
    border-radius: 8px;
    border: solid 1px ${Color.shadow2};  
  `}
  ${(props: Props) =>
    props.tip &&
    `
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      position: absolute;
      top: -8px;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(45deg);
      border: solid 2px ${Color.secondary};
      background: #fff;
    }
    &:after {
      content: "";
      width: 18px;
      height: 18px;
      display: block;
      position: absolute;
      top: -4px;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(45deg);
      background: #fff;
    }`}
`;

type Props = {
  thin?: boolean;
  tip?: boolean;
  children: React.ReactNode;
};

export default function WrapperBorder(props: Props) {
  return (
    <Wrapper tip={props.tip} thin={props.thin}>
      {props.children}
    </Wrapper>
  );
}

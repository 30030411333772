import React from 'react';
import GlobalStyle from '@/components/Common/GlobalStyle';
import Space from '@/components/Common/Space';
import { Color } from '@/constants/Color';
import H1 from '@/components/Common/H1';
import H3 from '@/components/Common/H3';
import List from '@/components/Common/List';

export default function ErrorInvalidUser() {
  return (
    <div>
      <GlobalStyle />
      <Space x={2} y={8}>
        <div style={{ background: Color.shadow, borderRadius: '28px' }}>
          <Space x={1} top={10}>
            <p style={{ textAlign: 'center' }}>
              <img src="/images/emoji_ghost.png" alt="error" style={{ width: '40px' }} />
            </p>
            <H1 title={`何かがおかしいようです`} align="center" />
          </Space>
          <Space x={1} top={8}>
            <H3 title="もしかして" align="center" />
          </Space>
          <Space x={1} top={2} bottom={12}>
            <List width={270} center={true}>
              <li>1. URLは正しいですか？もう一度リンクを開いてみてください。</li>
              <li>2. トラブルが起きているかもしれません。時間を置いてから、もう一度リンクを開いてみてください。</li>
            </List>
          </Space>
        </div>
      </Space>
    </div>
  );
}

import React from 'react';

import GlobalStyle from '@/components/Common/GlobalStyle';
import Space from '@/components/Common/Space';
import H1 from '@/components/Common/H1';
import List from '@/components/Common/List';
import ListItemEmoji from '@/components/Common/ListItemEmoji';
import Modal from '@/components/Common/Modal';
import useRouter from 'use-react-router';
import ProgressBar from '@/components/Common/ProgressBar';
import H2 from '@/components/Common/H2';
import H3 from '@/components/Common/H3';
import Button from '@/components/Common/Button';
import ButtonSecondary from '@/components/Common/ButtonSecondary';
import makeCompletedHelper from '@/pages/Completed/helper';
import RedirectIfHasNoResearcher from '@/components/Common/RedirectIfHasNoResearcherId';

export default function Completed() {
  const helper = makeCompletedHelper(useRouter(), React.useState<boolean>(false));
  return (
    <>
      <GlobalStyle />
      <RedirectIfHasNoResearcher researcherId={helper.researcherId} />

      <ProgressBar progress={100} fixed />

      {helper.isCompleted ? (
        <div>
          <Space top={10} bottom={1}>
            <H1 title="お疲れさまでした" align="center" />
          </Space>
          <ProgressBar progress={100} />
          <H3 title="すべて完了しました" align="center" />
          <Space y={8}>
            <H3 title={`チェック結果の校閲後\n問題なければメールで\nお礼が届きます`} align="center" />
          </Space>
        </div>
      ) : (
        <div>
          <Space top={10} bottom={1}>
            <H1 title="レポートを完了しますか？" align="center" />
          </Space>
          <Space bottom={5}>
            <H3 title={`全項目のチェックお疲れ様でした。\nレポートを完了できます。`} align="center" />
          </Space>
          <Space bottom={2}>
            <H2 title="最後にご確認ください" align="center" theme="danger" />
          </Space>
          <List width={270} center={true}>
            <ListItemEmoji emoji="/images/emoji_camera.png">
              正しい<b>写真</b>を添付しましたか？
            </ListItemEmoji>
            <ListItemEmoji emoji="/images/emoji_tophat.png">
              お店のスタッフが読んで理解できる文章を心がけましたか？
            </ListItemEmoji>
          </List>
          <Space top={5} bottom={1}>
            <Button label="完了する" onClick={helper.markAsComplete} />
          </Space>
          <ButtonSecondary label="修正する" onClick={helper.goBack} />
        </div>
      )}

      <Modal isOpen={helper.modal.isOpen} onClose={helper.modal.close}>
          <>
            <H1 title="完了しました！" />
            <Space top={3}>
              <p>
                調査内容の確認をいたします。問題がなければ１週間以内にお礼がメールに届きます。
                <br />
                <br />
                不備があった場合には連絡がございませんのでご了承ください。
              </p>
            </Space>
          </>
      </Modal>
    </>
  );
}
import React from 'react';
import styled from 'styled-components';

const UploadWrapper = styled.div`
  margin-top: 8px;
  input[type='file'] {
    display: none;
    -webkit-appearance: none;
  }
`;

const DummyInput = styled.label``;

interface Props {
  label: string;
  value: string;
  onSelectImage: (e: React.SyntheticEvent<HTMLInputElement>) => Promise<void>;
}

export default function UploadImage(props: Props) {
  return (
    <UploadWrapper>
      <DummyInput className={props.value ? 'selected' : ''}>
        <i className="material-icons md-36">file_upload</i>
        {props.label}
        <input type="file" onChange={props.onSelectImage} />
      </DummyInput>
    </UploadWrapper>
  );
}

import React from 'react';
import useRouter from 'use-react-router';

import GlobalStyle from '@/components/Common/GlobalStyle';
import H1 from '@/components/Common/H1';
import H2 from '@/components/Common/H2';
import H3 from '@/components/Common/H3';
import Space from '@/components/Common/Space';
import Button from '@/components/Common/Button';
import WrapperPrimary from '@/components/Common/WrapperPrimary';
import Offer from '@/components/Application/Offer';
import Terms from '@/components/Application/Terms';
import CompletePeopleCount from '@/components/Application/CompletePeopleCount';
import Modal from '@/components/Common/Modal';
import makeApplicationHelper from '@/pages/Application/helper';
import RedirectIfHasNoResearcher from '@/components/Common/RedirectIfHasNoResearcherId';
import { useDispatch } from 'react-redux';
import { useAssignment } from '@/stores/assignment';
import RedirectIfDuplicateApplication from '@/components/Common/RedirectIfDuplicateApplication';
import { Restaurant } from '@/types/models/restaurant';
import ApplicationQuestion from '@/components/Application/ApplicationQuestion';
import { AssignmentAnswer } from '@/types/models/assignment_answer';
import { createdAtToDueAt } from '@/helpers/assignment';
import RedirectIfAlreadyDone from '@/components/Common/RedirectIfAlreadyDone';
import { clientData } from "@/pages/Application/constants";

export default function Application() {
  const helper = makeApplicationHelper(
    useRouter(),
    useDispatch(),
    useAssignment(),
    React.useState<AssignmentAnswer[]>([]),
    React.useState<boolean>(true),
    React.useState<boolean>(false),
    React.useState<Restaurant | undefined>()
  );
  React.useEffect(helper.initialize);
  if (!helper || helper.initializing || !helper.restaurant || !helper.assignment.assignment) {
    return null;
  }
  return (
    <>
      <GlobalStyle />
      <RedirectIfHasNoResearcher researcherId={helper.researcherId} />
      <RedirectIfDuplicateApplication researcherId={helper.researcherId} />
      <RedirectIfAlreadyDone researcherId={helper.researcherId} />
      <p>
        <img src={clientData[helper.assignmentId].logo} alt={helper.restaurant.brand_name} style={{ width: '100%' }} />
      </p>
      <Offer
        image={clientData[helper.assignmentId].scene}
        title={clientData[helper.assignmentId].incentive.title}
        subtitle={clientData[helper.assignmentId].incentive.subtitle}
        restaurant={`${helper.restaurant.brand_name} ${helper.restaurant.name}`}
      />
      <Space top={7}>
        <H3 title="覆面調査とは？" align="center" />
        <Space top={1} bottom={4}>
          <H1 title={'お店の問題点を調査すると\nお礼がもらえる'} align="center" />
        </Space>
        <p>
          <img src="/images/howto_ms.png" alt="覆面調査とは" style={{ width: '100%' }} />
        </p>
      </Space>
      <Space top={10} bottom={7}>
        <H3 title="流れ" align="center" />
        <Space top={1} bottom={4}>
          <H1 title={'スマホからチェック完了後\nお礼が届きます'} align="center" />
        </Space>
        <p>
          <img src="/images/howto_flow.png" alt="覆面調査とは" style={{ width: '100%' }} />
        </p>
      </Space>
      <WrapperPrimary>
        <Space x={2} top={4}>
          <H3 title="今回の募集内容" align="center" />
          <Space top={1} bottom={4}>
            <H1 title={'まずは覆面調査員に\n応募しましょう'} align="center" />
          </Space>
          <Terms
            entryTerms={clientData[helper.assignmentId].entryTerms}
            restaurant={`${helper.restaurant.brand_name} ${helper.restaurant.name}`}
            address={helper.restaurant.address}
            gmbLink={helper.restaurant.url}
          />
        </Space>
        <CompletePeopleCount count={helper.assignment.assignment.number_of_completes} />
        <Button onClick={helper.modal.open} label="応募へ進む" />

        <Space top={2} bottom={15}>
          <div style={{ textAlign: 'center', fontWeight: 700 }}>
            <H2
              title={`応募期限：${createdAtToDueAt(helper.assignment.assignment.created_at)}まで`}
              theme="danger"
              align="center"
            />
            <p>応募結果はすぐに分かります</p>
          </div>
        </Space>
      </WrapperPrimary>

      <Modal isOpen={helper.modal.isOpen} onClose={helper.modal.close}>
        <H1 title="応募前にご確認ください" />
        <Space top={3}>
          {helper.assignment.assignment.questions.map(q => (
            <ApplicationQuestion
              key={q.id}
              applicationQuestionState={helper.answerState}
              question={q}
              onChange={helper.onChangeAnswer}
            />
          ))}
        </Space>
        <Button onClick={helper.handleClickApplyButton} label="応募する" />
      </Modal>
    </>
  );
}

import { useState, useRef, useEffect } from 'react';
import localStorage from './localStorage';

export function useBoolean(defaultValue = false): [boolean, () => void, () => void] {
  const [state, updateState] = useState<boolean>(defaultValue);
  const on = () => updateState(true);
  const off = () => updateState(false);
  return [state, on, off];
}

export function useToggle(defaultValue = false): [boolean, () => void] {
  const [state, updateState] = useState<boolean>(defaultValue);
  const toggle = () => updateState(!state);
  return [state, toggle];
}

export function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      return localStorage.get(key) || initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      localStorage.set(key, value);
      setStoredValue(value);
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue];
}

function createRootElement(id: string) {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

function addRootElement(rootElem: HTMLElement) {
  document.body.insertBefore(rootElem, document.body.lastElementChild!.nextElementSibling);
}

export function usePortal(id: string) {
  const rootElemRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const existingParent = document.getElementById(id);
    const parentElement = existingParent || createRootElement(id);

    if (!existingParent) {
      addRootElement(parentElement);
    }
    const current = rootElemRef.current;
    if (current === null) {
      return;
    }
    parentElement.appendChild(current);
    return () => {
      current.remove();
      if (parentElement.childNodes.length === -1) {
        parentElement.remove();
      }
    };
  }, [id]);

  // evaluate lazily
  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  };
  return getRootElem();
}

import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';

const Component = styled.p`
  color: ${Color.secondary};
  text-align: center;
  font-weight: bold;
  padding: 40px 16px;
`;

type Props = {
  count: number;
};

export default function CompletePeopleCount(props: Props) {
  return (
    <>
      {props.count && (
        <Component>
          <span>すでに{props.count}名が覆面調査を完了しています</span>
        </Component>
      )}
    </>
  );
}

import React, { useState } from 'react';

import { ChildProps, QuestionCard, trimSpaces } from '@/components/Questions/Question/helper';
import H2 from '@/components//Common/H2';
import styled from 'styled-components';
import { Color } from '@/constants/Color';

const ErrorMessage = styled.p`
  color: ${Color.danger};
  font-size: 13px;
  text-align: right;
  margin-top: 4px;
  i {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-bottom;
    margin-right: 3px;
  }
`;

const SuccessMessage = styled(ErrorMessage)`
  i.material-icons-outlined {
    font-size: 16px;
    color: ${Color.success};
  }
`;

function calcLeftCharCount(current: number, minLength = 0) {
  const left = minLength - current;
  if (left >= 0) {
    return left;
  }
  return 0;
}

export default function QuestionComment(props: ChildProps) {
  const [charLength, setCharLength] = useState<number>(props.answer.value.length);
  const onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setCharLength(trimSpaces(value).length);
    props.onChange({
      ...props.answer,
      value,
    });
  };
  const leftCharacters = calcLeftCharCount(charLength, props.question.minlength);
  return (
    <QuestionCard id={`question_${props.question.id}`}>
      <H2 title={`${props.order}.${props.question.title}`} />
      <textarea
        onChange={onChange}
        onKeyUp={onChange}
        value={props.answer.value}
        className={leftCharacters > 0 ? 'error' : 'selected'}
        rows={5}
      />
      {leftCharacters > 0 ? (
        <ErrorMessage>
          <i className="material-icons-outlined">error</i>残り{leftCharacters}文字
        </ErrorMessage>
      ) : (
        <SuccessMessage>
          <i className="material-icons-outlined">check_circle</i>
        </SuccessMessage>
      )}
    </QuestionCard>
  );
}

import React from 'react';
import styled from 'styled-components';
import anime from 'animejs';
import ReactSVG from 'react-svg';

import { Color } from '@/constants/Color';
import H1 from '@/components/Common/H1';

const Component = styled.div`
  background: ${Color.white};
  border-radius: 10px;
  padding: 32px 8px 0;
  margin-top: 24px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  .mail {
    position: absolute;
    width: 120px;
    height: 120px;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const CircleFireworks = styled.div`
  width: 250px;
  height: 250px;
  padding: 28px 22px 28px 28px;
  box-sizing: border-box;
  background: ${Color.shadow};
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;
const Headline = styled.div`
  padding-top: 104px;
  padding-bottom: 40px;
`;

const animeMail = {
  opacity: [0, 1],
  translateY: [-300, 100],
  duration: 1200,
  easing: 'easeOutSine',
};
const animeFire = {
  opacity: [0, 1],
  scale: [0, 1],
  translateY: [80, 0],
  duration: 800,
};
const animeWord = {
  opacity: [0, 1],
  translateY: [40, 0],
  easing: 'easeOutBounce',
};

type Props = {};

export default function ResultAnimationLost(props: Props) {
  return (
    <Component>
      <CircleFireworks
        ref={ref => {
          anime({ ...animeFire, targets: ref, delay: 1200 });
        }}
      >
        <div
          ref={ref => {
            anime({ ...animeWord, targets: ref, delay: 1700 });
          }}
        >
          <ReactSVG src="/images/lottery_lost_words_1.svg" />
        </div>
        <div
          style={{ marginTop: '-7px', marginRight: '-4px' }}
          ref={ref => {
            anime({ ...animeWord, targets: ref, delay: 1800 });
          }}
        >
          <ReactSVG src="/images/lottery_lost_words_2.svg" />
        </div>
        <div
          ref={ref => {
            anime({ ...animeWord, targets: ref, delay: 1900 });
          }}
        >
          <ReactSVG src="/images/lottery_lost_words_3.svg" />
        </div>
      </CircleFireworks>

      <div
        ref={ref => {
          anime({ ...animeMail, targets: ref, delay: 200 });
        }}
      >
        <ReactSVG className="mail" src="/images/lottery_lost.svg" />
      </div>

      <Headline
        ref={ref => {
          anime({ ...animeWord, targets: ref, delay: 2000 });
        }}
      >
        <H1 title={'応募ありがとうございました\n次回の募集をお待ちください'} align="center" />
      </Headline>
    </Component>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';
import H3 from '@/components/Common/H3';

const Wrapper = styled.div`
  width: 100%;
  padding: 46px 36px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  background: #fff;
  box-sizing: border-box;
  border-top: solid 8px #fafaf8;
  ${(props: Props) =>
    props.title.length < 7
      ? `flex-direction: row; align-items: center;`
      : `flex-direction: column; > p:first-child {padding-bottom: 16px;}`}
  .h3 {
    color: ${Color.on_background};
  }
`;

const LongText = styled.p`
  padding: 8px;
  font-size: 15px;
  line-height: 1.6148;
  font-weight: bold;
  color: ${Color.secondary};
`;
const SelectedText = styled.p`
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${Color.secondary};
`;

const SuccessIcon = styled.i`
  width: 28px;
  height: 28px;
  position: relative;
  display: block;
  margin-right: 16px;
  &::before {
    content: '';
    width: 28px;
    height: 28px;
    display: block;
    border: solid 3px ${Color.success};
    border-radius: 100%;
  }
`;

const DangerIcon = styled(SuccessIcon)`
  &::before,
  &::after {
    content: '';
    width: 3px;
    height: 30px;
    display: block;
    position: absolute;
    border-radius: 0;
    border: none;
    left: 12px;
    top: -1px;
    background: ${Color.danger};
  }
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
`;

type Props = {
  // key?: number;
  type?: string;
  title: string;
  value: string;
  score?: number;
  comment?: string;
};

export default function Component(props: Props) {
  return (
    <Wrapper title={props.title} value={props.value}>
      <H3>. {props.title}</H3>
      {(props.type === 'comment' || props.type === 'time') && <LongText>{props.value}</LongText>}
      {props.type === 'image' && <img src={props.value} alt={props.title} style={{ width: '100%' }} />}
      {(props.type !== 'image' && props.type !== 'comment' && props.type !== 'time') && (
        <SelectedText>
          {(props.score) ? <SuccessIcon /> : null }
          {(props.score !== undefined && props.score === 0) ? <DangerIcon /> : null }
          {props.value}
        </SelectedText>
      )}
      {props.comment ? (<LongText>{props.comment}</LongText>) : null}
    </Wrapper>
  );
}

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import LocalStorage from './localStorage';

export interface Response<T = object> {
  data: T;
  status: number;
}

class AxiosClient {
  public Axios: AxiosInstance;
  constructor() {
    const config: AxiosRequestConfig = {
      timeout: 8000,
    };
    config.baseURL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';
    this.Axios = axios.create(config);
    this.Axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const token = LocalStorage.get<string | undefined>('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (err: AxiosError) => {
        return Promise.reject(err);
      }
    );
    this.Axios.interceptors.response.use(
      (res: AxiosResponse) => {
        if (res.data.errors) {
          return Promise.reject(res);
        }
        return Promise.resolve(res);
      },
      (err: AxiosError) => Promise.reject(err)
    );
  }

  public get<T = object>(path: string, opts: object = {}): Promise<Response<T>> {
    return this.Axios.get(path, {
      ...opts,
    });
  }

  public post<T = object>(path: string, params: object = {}, opts: object = {}): Promise<Response<T>> {
    return this.Axios.post(path, params, {
      ...opts,
    });
  }

  public put<T = object>(path: string, params: object = {}, opts: object = {}): Promise<Response<T>> {
    return this.Axios.put(path, params, {
      ...opts,
    });
  }

  public delete<T = object>(path: string, opts: object = {}): Promise<Response<T>> {
    return this.Axios.delete(path, {
      ...opts,
    });
  }
}

export default new AxiosClient();

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  background: #fafaf8;
  padding-top: 32px;
`;
const Inner = styled.div`
  @media screen and (min-width: 800px) {
    width: 100%;
    max-width: 1200px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    > *:first-child {
      width: 30%;
      min-width: 370px;
    }
    > *:last-child {
      width: 70%;
      padding-left: 16px;

      padding-right: 16px;
    }
  }
`;

type Props = {
  children?: React.ReactNode;
};

export default function Component(props: Props) {
  return (
    <Wrapper>
      <Inner>{props.children}</Inner>
    </Wrapper>
  );
}

interface requiredActions {
    [key:string] : {
        title: string;
        description: string;
        item: {icon: string, body:string}[];
    }[];
}

export const requiredActionList:requiredActions = {
    "b98ce576-a3f7-4b85-8da3-66582292d7d2": [ // 串カツ田中
        {
            title: '目視で確認ください',
            description: '汚れてないか、清掃ができているか',
            item: [
                {icon: 'visibility', body: 'お店の看板や外観'},
                {icon: 'visibility', body: 'テーブル周り'},
                {icon: 'visibility', body: 'トイレ'},
            ]
        },
        {
            title: '話しかけてください',
            description: '店員が適切な行動をできているか',
            item: [
                {icon: 'record_voice_over', body: '「オススメはどれですか？」'},
            ]
        },
        {
            title: '注文&撮影ください',
            description: `盛り付けを確認するため\n渡された状態で撮影ください`,
            item: [
                {icon: 'camera_alt', body: 'お好きな串カツ'},
                {icon: 'camera_alt', body: 'チンチロハイボール'},
                {icon: 'camera_alt', body: 'お好きな料理二品以上'},
            ]
        }
    ],
    "14265e9a-f154-4417-9256-e851b3bccb61": [ // 第一興商
        {
            title: '目視で確認ください',
            description: '汚れてないか、清掃ができているか',
            item: [
                {icon: 'visibility', body: '店頭・テーブル・座席付近'},
                {icon: 'visibility', body: 'スタッフの身だしなみ'},
                {icon: 'visibility', body: 'トイレ'},
            ]
        },
        {
            title: '体感時間を把握ください',
            description: '不快な遅さを感じるかどうか',
            item: [
                {icon: 'timer', body: '最初に頼んだ飲み物'},
                {icon: 'timer', body: 'その他の料理・飲み物'},
            ]
        }
    ],
    "ddaf0c5e-e20d-401d-840e-2fffc9869e6b": [ // 第一興商
        {
            title: '目視で確認ください',
            description: '汚れてないか、清掃ができているか',
            item: [
                {icon: 'visibility', body: '店頭・テーブル・座席付近'},
                {icon: 'visibility', body: 'スタッフの身だしなみ'},
                {icon: 'visibility', body: 'トイレ'},
            ]
        },
        {
            title: '体感時間を把握ください',
            description: '不快な遅さを感じるかどうか',
            item: [
                {icon: 'timer', body: '最初に頼んだ飲み物'},
                {icon: 'timer', body: 'その他の料理・飲み物'},
            ]
        }
    ],
    "f2ef3426-be94-492a-9ea2-84c4c3f7363c": [ // 第一興商
        {
            title: '目視で確認ください',
            description: '汚れてないか、清掃ができているか',
            item: [
                {icon: 'visibility', body: '店頭・テーブル・座席付近'},
                {icon: 'visibility', body: 'スタッフの身だしなみ'},
                {icon: 'visibility', body: 'トイレ'},
            ]
        },
        {
            title: '体感時間を把握ください',
            description: '不快な遅さを感じるかどうか',
            item: [
                {icon: 'timer', body: '最初に頼んだ飲み物'},
                {icon: 'timer', body: 'その他の料理・飲み物'},
            ]
        }
    ],
};
import { AnswerManager } from '@/helpers/answer';
import { RouteComponentProps } from 'react-router';
import { ResearcherManager, ResearcherState } from '@/helpers/researcher';
import { completeResearch } from '@/api/research';
import { Dispatch, SetStateAction } from 'react';
import { wrapModalState, ModalState } from '@/components/Common/Modal';
import { ResearchReport } from '@/types/models/research_report';

function buildResearchAnswers(researcher: ResearcherState): ResearchReport {
  const answers = AnswerManager.all();
  return {
    researcher_id: researcher.id,
    answers,
    receipt: {
      image: researcher.receipt!.name,
    },
  };
}

interface CompletedHelper {
  researcherId: string;
  markAsComplete: () => void;
  goBack: () => void;
  modal: ModalState;
  isCompleted: boolean;
}

interface CompleteRouteProps {
  id: string;
}

export default function makeCompletedHelper(
  router: RouteComponentProps<CompleteRouteProps>,
  modalState: [boolean, Dispatch<SetStateAction<boolean>>]
): CompletedHelper {
  const researcherId = router.match.params.id
  const researcher = ResearcherManager.get(researcherId);
  const modal = wrapModalState(modalState);
  const markAsComplete = async () => {
    if (!researcher) {
      return;
    }
    await completeResearch(researcher.research_id, buildResearchAnswers(researcher));
    modal.open();
    ResearcherManager.save({
      ...researcher,
      isCompleted: true,
    });
  };
  const goBack = () => router.history.push(`/questions/${researcherId}`);
  return {
    researcherId,
    goBack,
    markAsComplete,
    modal,
    isCompleted: researcher ? researcher.isCompleted : false,
  };
}

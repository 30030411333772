import React from 'react';
import styled from 'styled-components';
import H3 from '@/components/Common/H3';

const Component = styled.div`
  width: 100%;
  background: #fafaf8;
`;
const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 16px;
  > :last-child {
    text-align: right;
  }
`;

type Props = {
  date: string;
};

export default function Content(props: Props) {
  return (
    <Component>
      <Wrapper>
        <H3>覆面調査レポート</H3>
        <H3>{props.date}</H3>
      </Wrapper>
    </Component>
  );
}

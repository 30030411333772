export const clientData: {
    [key:string] : {
        logo: string,
        scene: string,
        incentive: {
            title: string,
            subtitle: string,
        },
        entryTerms: {
            emoji: string,
            title: string,
        }[]
    }
} = {
    'b98ce576-a3f7-4b85-8da3-66582292d7d2': {
        logo: "/images/company/kushitanaka/logo_kushitanaka.png",
        scene: "/images/company/kushitanaka/hero_kushitanaka.png",
        incentive: {
            title: "2,500円分",
            subtitle: "amazonギフトカードもらえる"
        },
        entryTerms: [
            { emoji: '/images/emoji_alert.png', title: '18:00〜22:00以内来店' },
            { emoji: '/images/emoji_silhouette.png', title: '二人以上' },
            { emoji: '/images/emoji_person.png', title: '20代〜60代' },
        ]
    },
    '14265e9a-f154-4417-9256-e851b3bccb61': {
        logo: "/images/company/daiichikosho/logo_rakuzo.png",
        scene: "/images/company/daiichikosho/hero_rakuzo.png",
        incentive: {
            title: "3000円分",
            subtitle: "DKポイントもらえる"
        },
        entryTerms: [
            { emoji: '/images/emoji_alert.png', title: '17:00以降来店' },
            { emoji: '/images/emoji_silhouette.png', title: '二人以上(未成年者同伴不可)'},
            { emoji: '/images/emoji_person.png', title: '20代〜60代' },
        ]
    },
    'ddaf0c5e-e20d-401d-840e-2fffc9869e6b': {
        logo: "/images/company/daiichikosho/logo_kyomachishizuku.png",
        scene: "/images/company/daiichikosho/hero_kyomachishizuku.png",
        incentive: {
            title: "3000円分",
            subtitle: "DKポイントもらえる"
        },
        entryTerms: [
            { emoji: '/images/emoji_alert.png', title: '17:00以降来店' },
            { emoji: '/images/emoji_silhouette.png', title: '二人以上(未成年者同伴不可)'},
            { emoji: '/images/emoji_person.png', title: '20代〜60代' },
        ]
    },
    'f2ef3426-be94-492a-9ea2-84c4c3f7363c':  {
        logo: "/images/company/daiichikosho/logo_senyaichiya.png",
        scene: "/images/company/daiichikosho/hero_senyaichiya.png",
        incentive: {
            title: "3000円分",
            subtitle: "DKポイントもらえる"
        },
        entryTerms: [
            { emoji: '/images/emoji_alert.png', title: '17:00以降来店' },
            { emoji: '/images/emoji_silhouette.png', title: '二人以上(未成年者同伴不可)'},
            { emoji: '/images/emoji_person.png', title: '20代〜60代' },
        ]
    },
};
import React from 'react';

import { Props, curriedOnChange } from './helper';
//import QuestionSeparator from './Separator';
import QuestionSelect from './Select';
import QuestionComment from './Comment';
import QuestionTime from './Time';
import QuestionButtonsVertical from './ButtonsVertical';
import QuestionButtonsHorizontal from './ButtonsHorizontal';
import QuestionUploadImage from './UploadImage';
import useRouter from 'use-react-router';
import QuestionStaff from '@/components/Questions/Question/Staff/QuestionStaff';
import { ResearchAnswer } from '@/types/models/research_answer';
import QuestionDate from "@/components/Questions/Question/Date";

export default function Question(props: Props) {
  const router = useRouter();
  const [answer, updateValue] = React.useState<ResearchAnswer>(props.question.answer!);
  const onChange = curriedOnChange(props, updateValue, router);
  if (!props.visibleQuestionIds.includes(props.question.id)) {
    return null;
  }
  return (
    <>
      {props.question.type === 'time' && <QuestionTime {...props} answer={answer} onChange={onChange} />}
      {props.question.type === 'date' && <QuestionDate {...props} answer={answer} onChange={onChange} />}
      {props.question.type === 'dropdown' && <QuestionSelect {...props} answer={answer} onChange={onChange} />}
      {props.question.type === 'horizontal-buttons' && (
        <QuestionButtonsHorizontal {...props} answer={answer} onChange={onChange} />
      )}
      {props.question.type === 'vertical-buttons' && (
        <QuestionButtonsVertical {...props} answer={answer} onChange={onChange} />
      )}
      {props.question.type === 'input' && <QuestionComment {...props} answer={answer} onChange={onChange} />}
      {props.question.type === 'image' && <QuestionUploadImage {...props} answer={answer} onChange={onChange} />}
      {props.question.type === 'staff' && <QuestionStaff {...props} answer={answer} onChange={onChange} />}
    </>
  );
}

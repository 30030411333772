import useRouter from 'use-react-router';

import { ResearcherManager } from '@/helpers/researcher';

interface Props {
  researcherId: string;
}

export default function RedirectIfHasNoResearcher(props: Props) {
  const router = useRouter();
  if (!ResearcherManager.get(props.researcherId)) {
    router.history.push('/err/invalid-user');
  }
  return null;
}

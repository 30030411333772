import localStorage from './localStorage';
import { ResearchAnswer } from '@/types/models/research_answer';

const answerManagerStorageKey = 'ms_answers';

export class AnswerManager {
  static save(answer: ResearchAnswer) {
    const answers = AnswerManager.all().filter(a => a.question_id !== answer.question_id);
    if (answer.value) {
      answers.push(answer);
    }
    localStorage.set(answerManagerStorageKey, answers);
  }
  static all(): ResearchAnswer[] {
    return localStorage.get<ResearchAnswer[]>(answerManagerStorageKey) || [];
  }
  static clear() {
    localStorage.set(answerManagerStorageKey, []);
  }
}

import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';
import H3 from '@/components/Common/H3';
import WrapperPrimary from '@/components/Common/WrapperPrimary';

const Component = styled.div`
  width: 100%;
  img {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
  }
  p {
    margin-left: auto;
    margin-right: auto;
  }
  p:first-child {
    padding-top: 25px;
  }
  p:last-child {
    width: 88%;
    border-top: solid 1px ${Color.on_primary};
    margin-top: 25px;
    padding-top: 21px;
    padding-bottom: 21px;
  }
`;

const TextDisplay = styled.p`
  font-size: 28px;
  letter-spacing: 0.04em;
  font-weight: 600;
  text-align: center;
`;

type Props = {
  image: string;
  title: string;
  subtitle: string;
  restaurant: string;
};

export default function Offer(props: Props) {
  return (
    <Component>
      <img src={props.image} alt={props.restaurant} />
      <WrapperPrimary>
        <H3 title="覆面調査に協力すると" align="center" />
        <TextDisplay>{props.title}</TextDisplay>
        <H3 title={props.subtitle} align="center" />
        <H3 title={props.restaurant} align="center" />
      </WrapperPrimary>
    </Component>
  );
}

import { AnswerManager } from '@/helpers/answer';
import { Dispatch as HooksDispatch, SetStateAction } from 'react';
import { ValidAnswersState } from '@/pages/Questions/helper';
import { ResearchState } from '@/stores/research';
import { ResearchAnswer } from '@/types/models/research_answer';
import { ResearchQuestion } from '@/types/models/research_question';

export function wrapValidAnswerState(
  validAnswerState: [number[], HooksDispatch<SetStateAction<number[]>>]
): ValidAnswersState {
  const [questionIds, update] = validAnswerState;
  return {
    questionIds,
    update,
  };
}

export function calcProgress(validAnswerState: ValidAnswersState, research: ResearchState) {
  const answeredQuestions = validAnswerState.questionIds.length;
  const totalQuestions = research.result ? research.result.questions.length : 0;
  return answeredQuestions > 0 ? Math.round((answeredQuestions / totalQuestions) * 100) : 0;
}

export function calcRemain(validAnswerState: ValidAnswersState, research: ResearchState) {
  const answeredQuestions = validAnswerState.questionIds.length;
  const totalQuestions = research.result ? research.result.questions.length : 0;
  return totalQuestions - answeredQuestions;
}

function findAnswerByQuestionId(id: number): ResearchAnswer {
  const found = AnswerManager.all().find(a => a.question_id === id);
  if (!found) {
    return {
      question_id: id,
      value: '',
    };
  }
  return found;
}

export function rehydrate(questions: ResearchQuestion[]) {
  return questions.map((q, idx) => {
    const answer = findAnswerByQuestionId(q.id);
    const next = {
      ...q,
      answer,
    };
    const nextQuestion = questions[idx + 1];
    if (!nextQuestion) {
      return next;
    }
    return {
      ...next,
      nextQuestionId: nextQuestion.id,
    };
  });
}

export const Color = {
  primary: '#FFE143',
  primary_valiant: '#FFE143',
  secondary: '#050F1E',
  shadow: '#F2F2F2',
  shadow2: '#D3D1BC',
  white: '#FFFFFF',
  background: '#FFFFFF',
  on_primary: '#050F1E',
  on_secondary: '#FFFFFF',
  on_background: '#54524C',
  on_danger: '#FFFFFF',
  danger: '#F00707',
  success: '#00C853',
};

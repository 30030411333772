import React from 'react';
import GlobalStyle from '@/components/Common/GlobalStyle';
import Space from '@/components/Common/Space';
import Layout from '@/components/Report/Layout';
import Header from '@/components/Report/Header';
import ShopScore from '@/components/Report/ShopScore';
import ExperienceScore from '@/components/Report/ExperienceScore';
import Answer from '@/components/Report/Answer';
import Receipt from '@/components/Report/Receipt';
import makeReportHelper from "@/pages/Report/helper";
import useRouter from "use-react-router";
import {ResearchAnswer} from "@/types/models/research_answer";
import {ResearchQuestionExperience} from "@/types/models/research_question_experience";
import {useReport} from "@/stores/report";
import {useDispatch} from "react-redux";

export default function Report() {
  const helper = makeReportHelper(
      useRouter(),
      useDispatch(),
      useReport(),
      React.useState<boolean>(true),
      React.useState<{[key:number]:ResearchQuestionExperience}|undefined>(),
      React.useState<{[key:number]:ResearchAnswer}|undefined>(),
      React.useState<number>(0),
  );
  React.useEffect(helper.initialize);
  if (
      !helper.report
      || !helper.report.completed_at
      || !helper.report.receipt
      || !helper.report.restaurant
      || !helper.report.questions
      || !helper.answers
      || !helper.experiences
  ) {
      return null;
  }
  return (
      <>
        <GlobalStyle onlyMobile={false} />
        <Header date={helper.report.completed_at} />
        <Layout>
          <Space x={2}>
            <ShopScore restaurant={helper.report.restaurant.name} score={helper.score} />
              <Space x={1} bottom={4}>
                {Object.keys(helper.experiences).map(key =>{
                  if (!helper.experiences) return;
                  if (!helper.experiences[parseInt(key)].cap) return;
                  return (<ExperienceScore
                    key={parseInt(key)}
                    scene={helper.experiences[parseInt(key)].label}
                    score={helper.experiences[parseInt(key)].score}
                    cap={helper.experiences[parseInt(key)].cap}
                  />);
                })}
              </Space>
          </Space>
          <Space bottom={4}>
            {helper.report.questions.map(question => {
              if (!helper.answers) return;
              return (<Answer
                type={question.type}
                title={question.title}
                value={helper.answers[question.id].value}
                score={helper.answers[question.id].score}
                comment={helper.answers[question.id].comment}
              />);
            })}
            <Receipt
              tel={helper.report.receipt.tel}
              name={helper.report.receipt.name}
              address={helper.report.receipt.address}
              image={helper.report.receipt.image}
            />
          </Space>
        </Layout>
      </>
  );
}

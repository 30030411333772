import localStorage from './localStorage';
import { Researcher } from '@/types/models/researcher';
import { Restaurant } from '@/types/models/restaurant';
import dayjs from 'dayjs';
import { UploadedImage } from '@/types/models/uploaded_image';

const researcherManagerStorageKey = 'ms_researcher';

export type ResearcherState = Researcher & {
  isCompleted: boolean;
  restaurant: Restaurant;
  lotteryResult?: boolean;
  dueAt?: string;
  receipt?: UploadedImage;
};

export function formatDueAt(dueAt: string): string {
  return dayjs(dueAt).format('M月D日');
}

export class ResearcherManager {
  static save(researcher: ResearcherState) {
    const researchers = ResearcherManager.all();
    let added = false;
    const next = researchers.map(r => {
      if (r.id !== researcher.id) {
        return r;
      }
      added = true;
      return researcher;
    });
    if (!added) {
      next.push(researcher);
    }
    localStorage.set(researcherManagerStorageKey, next);
  }
  static flush() {
    localStorage.set(researcherManagerStorageKey, false);
  }
  static all(): ResearcherState[] {
    return localStorage.get<ResearcherState[]>(researcherManagerStorageKey) || [];
  }
  static get(researcherId: string): ResearcherState | false {
    const found = ResearcherManager.all().find(r => r.id === researcherId);
    if (!found) {
      return false;
    }
    return found;
  }
  static isWon(researcherId: string): boolean {
    const researcher = ResearcherManager.get(researcherId);
    if (!researcher || researcher.lotteryResult === undefined) {
      return false;
    }
    return researcher.lotteryResult;
  }
  static getRestaurant(researcherId: string): Restaurant | false {
    const researcher = ResearcherManager.get(researcherId);
    if (!researcher || !researcher.restaurant) {
      return false;
    }
    return researcher.restaurant;
  }
  static getDueAt(researcherId: string): string {
    const researcher = ResearcherManager.get(researcherId);
    if (!researcher || !researcher.dueAt) {
      return '';
    }
    return formatDueAt(researcher.dueAt);
  }
  static getAssignmentId(researcherId: string): string | false {
    const researcher = ResearcherManager.get(researcherId);
    if (!researcher || !researcher.assignment_id) {
      return false;
    }
    return researcher.assignment_id;
  }
}

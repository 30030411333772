import React from 'react';

import { uploadImage } from '@/api/upload';

import { ChildProps, QuestionCard } from '@/components/Questions/Question/helper';
import styled from 'styled-components';
import H2 from '@/components//Common/H2';
import { Color } from '@/constants/Color';
import { UploadedImage } from '@/types/models/uploaded_image';
import UploadImage from '@/components/Common/UploadImage';

const UpdatedImage = styled.div`
  margin-top: 8px;
  padding: 20px;
  display: inline-block;
  box-sizing: border-box;
  border: solid 2px ${Color.shadow};
  border-radius: 7px;
  position: relative;
  i {
    display: block;
    color: ${Color.success};
    font-size: 32px;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border: solid 1px #fff;
    border-radius: 100%;
  }
  img {
    display: block;
    height: 100px;
    width: auto;
    max-width: 226px;
  }
`;

const Loading = styled.div`
  width: 100px;
  padding: 20px;
  border: solid 2px ${Color.shadow};
  border-radius: 7px;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  @keyframes ball-pulse-sync {
    0% {
      transform: translateY(0);
    }
    33% {
      transform: translateY(5px);
    }
    66% {
      transform: translateY(-10px);
    }
  }
  > div {
    width: 12px;
    height: 12px;
    background: ${Color.shadow2};
    border-radius: 100%;
  }
  > div:first-child {
    animation: ball-pulse-sync 0.6s -0.2s infinite ease-in-out;
  }
  > div:nth-child(2n) {
    animation: ball-pulse-sync 0.6s -0.1s infinite ease-in-out;
  }
  > div:last-child {
    animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
  }
`;

const ErrorMessage = styled.p`
  color: ${Color.danger};
  font-size: 13px;
  text-align: center;
  margin-top: 4px;
  i {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-bottom;
    margin-right: 3px;
  }
`;

async function upload(image: File): Promise<UploadedImage> {
  return await uploadImage({
    image,
  });
}

type uploadStatus = 'waiting' | 'requesting' | 'failed';
export default function QuestionUploadImage(props: ChildProps) {
  const [status, updateStatus] = React.useState<uploadStatus>('waiting');
  const onSelectImage = async (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || !e.currentTarget.files[0]) {
      return;
    }
    updateStatus('requesting');
    try {
      const res = await upload(e.currentTarget.files[0]);
      props.onChange({
        ...props.answer,
        value: res.thumbnail,
      });
      updateStatus('waiting');
    } catch (e) {
      console.error(e);
      updateStatus('failed');
    }
  };
  return (
    <QuestionCard id={`question_${props.question.id}`}>
      <H2 title={`${props.order}.${props.question.title}`} />
      <div>
        <img src={props.question.example} alt={props.question.id.toString()} style={{ width: '100%' }} />
      </div>
      <UploadImage label="画像をアップロード" value={props.answer.value} onSelectImage={onSelectImage} />

      {status === 'failed' && (
        <ErrorMessage>
          <i className="material-icons-outlined">error</i>画面を更新しリトライください
        </ErrorMessage>
      )}

      {status === 'requesting' && (
        <Loading>
          <div />
          <div />
          <div />
        </Loading>
      )}

      {status === 'waiting' && props.answer.value && (
        <UpdatedImage>
          <i className="material-icons-outlined">check_circle</i>
          <img src={props.answer.value} alt="" />
        </UpdatedImage>
      )}
    </QuestionCard>
  );
}

import React, { Dispatch as HooksDispatch, SetStateAction } from 'react';
import { RouteComponentProps } from 'react-router';
import { formatDueAt, ResearcherManager, ResearcherState } from '@/helpers/researcher';
import { ModalState, wrapModalState } from '@/components/Common/Modal';
import { Dispatch } from 'redux';
import researchModule, { ResearchState } from '@/stores/research';
import { Restaurant } from '@/types/models/restaurant';
import { Researcher } from '@/types/models/researcher';
import { fetchResearch } from '@/api/research';
import { AnswerManager } from '@/helpers/answer';
import { ValidAnswersState } from '@/pages/Questions/helper';
import { calcProgress, calcRemain, wrapValidAnswerState } from '@/helpers/research';
import { UploadedImage } from '@/types/models/uploaded_image';
import { uploadImage } from '@/api/upload';

function getRestaurant(researcher: ResearcherState | false): Restaurant | false {
  if (!researcher) {
    return false;
  }
  return researcher.restaurant;
}

function getDueAt(researcher: ResearcherState | false): string {
  if (!researcher || !researcher.dueAt) {
    return '';
  }
  return formatDueAt(researcher.dueAt);
}

function curriedInitialize(
  researcher: Researcher | false,
  research: ResearchState,
  dispatch: Dispatch,
  validAnswerState: ValidAnswersState,
  initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>]
) {
  return () => {
    const [initializing, setInitializing] = initializingState;
    if (!initializing) {
      return;
    }
    (async () => {
      if (!researcher) {
        return;
      }
      if (!research.pristine) {
        return;
      }
      const res = await fetchResearch(researcher.research_id);
      dispatch(researchModule.actions.initialize(res));
    })();
    if (!research.result) {
      return;
    }
    const valid = new Set<number>();
    AnswerManager.all().forEach(answer => {
      if (!answer.value) {
        return;
      }
      valid.add(answer.question_id);
    });
    validAnswerState.update(Array.from(valid));
    setInitializing(false);
  };
}

const dummyImageUrl = 'https://wired.jp/wp-content/uploads/2018/01/GettyImages-522585140.jpg';

async function upload(image: File): Promise<UploadedImage> {
  if (process.env.NODE_ENV === 'development') {
    return new Promise(resolve =>
      resolve({
        name: 'ガッツ',
        origin: dummyImageUrl,
        thumbnail: dummyImageUrl,
      })
    );
  }
  return await uploadImage({
    image,
  });
}

function curriedOnSelectImage(
  receiptUploadState: [boolean | undefined, HooksDispatch<SetStateAction<boolean | undefined>>],
  receiptImageState: [UploadedImage | false, HooksDispatch<SetStateAction<UploadedImage | false>>]
) {
  const updateUploadStatus = receiptUploadState['1'];
  const updateImage = receiptImageState['1'];
  return async (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files || !e.currentTarget.files[0]) {
      return;
    }
    updateUploadStatus(true);
    try {
      const res = await upload(e.currentTarget.files[0]);
      updateImage(res);
    } catch (e) {
      console.error(e);
      updateUploadStatus(false);
    }
  };
}

export interface ResumeRouteProps {
  id: string;
}

interface ResumeHelper {
  researcherId: string;
  assignmentId: string | false;
  initializing: boolean;
  initialize: () => void;
  receiptUploaded: boolean | undefined;
  validAnswerState: ValidAnswersState;
  goQuestions: () => void;
  modal: ModalState;
  restaurant: Restaurant | false;
  dueAt: string;
  remain: number;
  progress: number;
  onSelectReceiptImage: (e: React.SyntheticEvent<HTMLInputElement>) => Promise<void>;
  receiptImage: UploadedImage | false;
}

export function makeResumeHelper(
  router: RouteComponentProps<ResumeRouteProps>,
  dispatch: Dispatch,
  research: ResearchState,
  validAnswerState: [number[], HooksDispatch<SetStateAction<number[]>>],
  initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>],
  modalState: [boolean, HooksDispatch<SetStateAction<boolean>>],
  receiptUploadState: [boolean | undefined, HooksDispatch<SetStateAction<boolean | undefined>>],
  receiptImageState: [UploadedImage | false, HooksDispatch<SetStateAction<UploadedImage | false>>]
): ResumeHelper {
  const [initializing] = initializingState;
  const [receiptImage] = receiptImageState;
  const modal = wrapModalState(modalState);
  const wrappedValidAnswerState = wrapValidAnswerState(validAnswerState);
  const researcherId = router.match.params.id;
  const researcher = ResearcherManager.get(researcherId);
  const assignmentId = ResearcherManager.getAssignmentId(researcherId);
  const goQuestions = () => {
    if (researcher && receiptImage !== false) {
      ResearcherManager.save({
        ...researcher,
        receipt: receiptImage,
      });
    }
    router.history.push(`/questions/${researcherId}`);
  };
  const initialize = curriedInitialize(researcher, research, dispatch, wrappedValidAnswerState, initializingState);
  const restaurant = getRestaurant(researcher);
  const dueAt = getDueAt(researcher);
  const progress = calcProgress(wrappedValidAnswerState, research);
  const remain = calcRemain(wrappedValidAnswerState, research);
  const [receiptUploaded] = receiptUploadState;
  const onSelectReceiptImage = curriedOnSelectImage(receiptUploadState, receiptImageState);
  return {
    researcherId,
    assignmentId,
    initializing,
    receiptUploaded,
    modal,
    validAnswerState: wrappedValidAnswerState,
    goQuestions,
    initialize,
    restaurant,
    dueAt,
    progress,
    remain,
    onSelectReceiptImage,
    receiptImage,
  };
}

import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  list-style: none;
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

type Props = {
    image: string;
    address?: string;
    tel?: string
    name?: string
}

export default function Component(props: Props) {
    return (
        <Wrapper>
            {props.image ? (<img src={props.image} alt="レシート画像" style={{ width: '100%' }} />) : null }
        </Wrapper>
    );
}


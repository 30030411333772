import React from 'react';
import { AssignmentQuestion } from '@/types/models/assignemnt_question';
import styled from 'styled-components';
import { Color } from '@/constants/Color';
import { AssigmentAnswerState } from '@/pages/Application/helper';
import H3 from '@/components/Common/H3';

interface Props {
  applicationQuestionState: AssigmentAnswerState;
  question: AssignmentQuestion;
  onChange: (questionId: number, value: string) => void;
}

const SelectWrapper = styled.div`
  position: relative;
  margin-top: 4px;
  margin-bottom: 24px;
  &::after {
    content: 'expand_more';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: ${Color.shadow2};
    pointer-events: none;
    font-family: 'Material Icons', serif;
  }
  select, input[type=time] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    &::-ms-expand {
      display: none;
    }
  }
  input, select, textarea, button, label {
    width: 100%;
    max-width: 100%;
    min-height: 52px;
    box-sizing: border-box;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: solid 1px ${Color.shadow2};
    background: ${Color.shadow};
    outline: none;
    font-size: 15px;
    cursor: pointer;
  }
  textarea {
    resize: vertical;
  } 
  input.selected, select.selected, textarea.selected, button.selected, label.selected
  input:focus, select:focus, textarea:focus, button:focus, label:focus {
    background: ${Color.white}
    border: solid 1px ${Color.shadow2};
  }
`;
function curriedHandleOnChange(questionId: number, state: AssigmentAnswerState) {
  return (e: React.SyntheticEvent<HTMLSelectElement>) => {
    state.add({
      question_id: questionId,
      value: e.currentTarget.value,
    });
  };
}
export default function ApplicationQuestion(props: Props) {
  const value = props.applicationQuestionState.get(props.question.id);
  const handleOnChange = curriedHandleOnChange(props.question.id, props.applicationQuestionState);
  return (
    <>
      <H3>{props.question.title}</H3>
      <SelectWrapper>
        <select value={value} onChange={handleOnChange} className={value ? 'selected' : ''}>
          <option value="">選択してください</option>
          {props.question.choices &&
            props.question.choices.map(c => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
        </select>
      </SelectWrapper>
    </>
  );
}

import { createSlice, PayloadAction } from 'redux-starter-kit';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { Assignment } from '@/types/models/assignment';

export interface AssignmentState {
  pristine: boolean;
  assignment: Assignment | false;
}

const initialState: AssignmentState = {
  pristine: true,
  assignment: false,
};

const assignmentModule = createSlice({
  slice: 'assignment',
  initialState,
  reducers: {
    initialize(state, value: PayloadAction<Assignment>) {
      state.pristine = false;
      state.assignment = value.payload;
    },
  },
});

export const useAssignment = () => {
  return useSelector((state: AppState) => state.assignment);
};

export default assignmentModule;

import React from 'react';
import useRouter from 'use-react-router';

import GlobalStyle from '@/components/Common/GlobalStyle';
import Space from '@/components/Common/Space';
import H1 from '@/components/Common/H1';
import H2 from '@/components/Common/H2';
import List from '@/components/Common/List';
import ListItemEmoji from '@/components/Common/ListItemEmoji';
import ProgressBar from '@/components/Common/ProgressBar';
import Button from '@/components/Common/Button';
import CardDescription from '@/components/Top/CardDescription';
import WrapperBorder from '@/components/Top/WrapperBorder';
import ReceiptLoading from '@/components/Top/ReceiptLoading';
import ActionTitle from '@/components/Top/ActionTitle';
import ActionList from '@/components/Top/ActionList';
import WrapperPrimary from '@/components/Common/WrapperPrimary';
import WrapperWhite from '@/components/Common/WrapperWhite';
import Modal from '@/components/Common/Modal';
import RedirectIfHasNoResearcher from '@/components/Common/RedirectIfHasNoResearcherId';
import { useDispatch } from 'react-redux';
import { useResearch } from '@/stores/research';
import UploadImage from '@/components/Common/UploadImage';
import RedirectIfAlreadyDone from '@/components/Common/RedirectIfAlreadyDone';
import { makeResumeHelper } from '@/pages/Resume/helper';
import { UploadedImage } from '@/types/models/uploaded_image';
import styled from 'styled-components';
import {requiredActionList} from "@/pages/Resume/constants";
import {Color} from "@/constants/Color";

const ReceiptImageWrapper = styled.div`
  img {
    display: block;
    max-width: 100%;
    object-fit: contain;
  }
`;

const ErrorMessage = styled.p`
  color: ${Color.danger};
  font-size: 13px;
  text-align: center;
  margin-top: 4px;
  i {
    display: inline-block;
    font-size: 16px;
    vertical-align: text-bottom;
    margin-right: 3px;
  }
`;

export default function Top() {
  const helper = makeResumeHelper(
    useRouter(),
    useDispatch(),
    useResearch(),
    React.useState<number[]>([]),
    React.useState<boolean>(true),
    React.useState<boolean>(false),
    React.useState<boolean | undefined>(undefined),
    React.useState<UploadedImage | false>(false)
  );
  React.useEffect(helper.initialize);
  if (helper.initializing || !helper.restaurant || !helper.assignmentId) {
    return <RedirectIfHasNoResearcher researcherId={helper.researcherId} />;
  }

  const requiredActions = requiredActionList[helper.assignmentId];
  if (!requiredActions) {
    return <RedirectIfHasNoResearcher researcherId={helper.researcherId} />;
  }
  return (
    <>
      <RedirectIfAlreadyDone researcherId={helper.researcherId} />
      <GlobalStyle />

      <Space top={8}>
        <H1 title="GUIDELINE" align="center" />
      </Space>

      <Space top={8}>
        <H1 title={`あなたは覆面調査員\n全てチェックしましょう`} align="center" />
        <Space top={2}>
          <ProgressBar progress={helper.progress} />
          <p style={{ textAlign: 'center' }}>残り{helper.remain}件</p>
        </Space>
        <Space top={4} bottom={3}>
          <H2 title={`以下のことが守られない場合\n報酬が支払われません`} align="center" theme="danger" />
        </Space>
        <List width={300} center={true}>
          <ListItemEmoji emoji="/images/emoji_receipt.png">
            来店を証明する<b>レシート</b>の添付
          </ListItemEmoji>
          <ListItemEmoji emoji="/images/emoji_calendar.png">
            当選日から期限<b>{helper.dueAt}</b>までの完了
          </ListItemEmoji>
          <ListItemEmoji emoji="/images/emoji_home.png">
            {helper.restaurant.brand_name} <b>{helper.restaurant.name}</b>に<br />
            <b>2名以上</b>・<b>18:00-22:00</b>での来店
          </ListItemEmoji>
          <ListItemEmoji emoji="/images/emoji_tophat.png">スタッフに伝わる文章であること</ListItemEmoji>
        </List>
      </Space>

      <Space top={14} bottom={3}>
        <H1 title={`下記の点を観察し\n退店後に入力ください`} align="center" />
      </Space>

      {
        requiredActions.map(r => (
          <WrapperBorder thin={true}>
            <ActionTitle title={r.title} description={r.description} />
            <Space x={4} top={2} bottom={4}>
              {r.item.map(i => (<ActionList icon={i.icon}>{i.body}</ActionList>))}
            </Space>
          </WrapperBorder>
        ))
      }

      <Space top={3} />

      <WrapperBorder>
        <Space x={2} y={3}>
          <CardDescription title="報酬の対象外となるケース">
            下記注文をした場合は報酬の対象外です。
            <br />
            ・飲み放題
            <br />
            ・食べ放題
            <br />
            <br />
            支払いは現金のみ。クーポン併用不可。
            <br />
            ・クレジットカードで会計された場合は謝礼のお支払いはできません。
            <br />
            ・金券や株主優待券などをご利用の場合、ご利用分の金額は謝礼対象になりません。
            <br />
            ・同伴者含め、当モニター以外のサービス（他社サービスや店舗が発行するクーポン・割引など）のご利用は禁止です。利用が確認された場合、謝礼のお支払いはできません。
          </CardDescription>
        </Space>
      </WrapperBorder>

      <Space top={4} />

      <WrapperPrimary>
        {helper.progress === 0 && (
          <>
            <Space y={4}>
              <H1 title={`来店したレシートを添付して\nチェックを開始しましょう`} align="center" />
            </Space>
            <Space x={2} bottom={2}>
              <WrapperWhite>
                <Space x={2} y={2}>
                  <img src="/images/howto_receipt.png" style={{ width: '100%' }} alt="talk" />
                  <UploadImage
                    label="レシート画像を添付"
                    value={helper.receiptImage ? helper.receiptImage.thumbnail : ''}
                    onSelectImage={helper.onSelectReceiptImage}
                  />
                  {helper.receiptUploaded === false && (
                    <ErrorMessage>
                      <i className="material-icons-outlined">error</i>画面を更新しリトライください。png, jpg, jpegでない可能性があります。
                    </ErrorMessage>
                  )}
                </Space>
                {helper.receiptImage && helper.receiptImage.thumbnail && (
                  <ReceiptImageWrapper>
                    <img src={helper.receiptImage.thumbnail} alt={helper.receiptImage.name} />
                  </ReceiptImageWrapper>
                )}
              </WrapperWhite>
            </Space>
            {helper.receiptImage && helper.receiptImage.thumbnail && (
              <Space bottom={16}>
                <Button label="はじめる" onClick={helper.goQuestions} />
              </Space>
            )}
          </>
        )}
        {helper.progress > 0 && (
          <Space y={4}>
            <Button label="チェックを再開する" onClick={helper.goQuestions} />
          </Space>
        )}
      </WrapperPrimary>

      <Modal isOpen={helper.modal.isOpen} onClose={helper.modal.close}>
        <ReceiptLoading finish={false} />
      </Modal>
    </>
  );
}

import React from 'react';

import GlobalStyle from '@/components/Common/GlobalStyle';
import H1 from '@/components/Common/H1';
import { Color } from '@/constants/Color';
import Space from '@/components/Common/Space';

export default function Closed() {
  return (
    <>
      <GlobalStyle />
      <Space x={2} y={8}>
        <div style={{ background: Color.on_background, color: Color.white, borderRadius: '28px' }}>
          <Space x={1} top={12}>
            <p style={{ textAlign: 'center' }}>
              <img src="/images/emoji_crescent_moon.png" alt="error" style={{ width: '40px' }} />
            </p>
          </Space>
          <Space x={1} top={3} bottom={12}>
            <H1 title={`すでに終了しました\n次回募集をお待ちください`} align="center" theme="white" />
          </Space>
        </div>
      </Space>
    </>
  );
}

import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Color } from '@/constants/Color';

const Wrapper = createGlobalStyle`
  *,
  *::before,
  *::after{box-sizing:border-box;}
  a{text-decoration:none; color:inherit; cursor:pointer;}
  button{background-color:transparent; color:inherit; border-width:0; padding:0; cursor:pointer;}
  figure{margin:0;}
  input::-moz-focus-inner {border:0; padding:0; margin:0;}
  ul, ol, dd{margin:0; padding:0; list-style:none;}
  h1, h2, h3, h4, h5, h6{margin:0; font-size:inherit; font-weight:inherit;}
  p{margin:0;}
  cite {font-style:normal;}
  fieldset{border-width:0; padding:0; margin:0;}
  
  html {
    background: ${Color.secondary};
    width: 100%;
    overflow-x: hidden;
  }
  body {
    margin: 0;
    font-family: Roboto, 'ヒラギノ角ゴ Pro W3','ヒラギノ角ゴ W3', 'メイリオ', system-ui;
    font-size: 15px;
    color: ${Color.on_background};
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    background: #fff;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    ${(props: Props) => props.onlyMobile === true && `max-width: 480px;`}
    &::after{
      content: "© 2019 Mobiile Order Lab inc.";
      position:absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      display: inline-block;
      text-align: center;
      pointer-events: none;
      font-size: 10px;
      opacity: .3;
      transform: scale(.8);
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

type Props = {
  onlyMobile?: boolean;
  children?: React.ReactNode;
};

GlobalStyle.defaultProps = {
  onlyMobile: true,
};

export default function GlobalStyle(props: Props) {
  return <Wrapper onlyMobile={props.onlyMobile} />;
}

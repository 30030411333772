import { createSlice, PayloadAction } from 'redux-starter-kit';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { Research } from '@/types/models/research';

export interface ResearchState {
  pristine: boolean;
  result: Research | false;
}

const initialState: ResearchState = {
  pristine: true,
  result: false,
};

const researchModule = createSlice({
  slice: 'research',
  initialState,
  reducers: {
    initialize(state, value: PayloadAction<Research>) {
      state.pristine = false;
      state.result = value.payload;
    },
    reset(state) {
      state.pristine = true;
      state.result = false;
    },
  },
});

export const useResearch = () => {
  return useSelector((state: AppState) => state.research);
};

export default researchModule;

import React from 'react';
import useRouter from 'use-react-router';

interface Props {
  children: React.ReactNode;
}

export default function ScrollToTopOnPathChanges(props: Props) {
  const router = useRouter();
  const [path, updatePath] = React.useState<string>(router.location.pathname);
  React.useEffect(() => {
    if (path !== router.location.pathname) {
      window.scrollTo(0, 0);
    }
    updatePath(router.location.pathname);
  }, [path, router.location.pathname]);
  return <>{props.children}</>;
}

import React from 'react';
import styled from 'styled-components';
import H1 from '@/components/Common/H1';

const Wrapper = styled.div`
  text-align: center;
  margin-top: 32px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  p {
    margin-top: 8px;
    color: #666;
    white-space: pre-line;
  }
`;

type Props = {
  title: string;
  description?: string;
};

export default function Conponent(props: Props) {
  return (
    <Wrapper>
      <H1 title={props.title} theme="danger" align="center" />
      <p>{props.description}</p>
    </Wrapper>
  );
}

import React from 'react';

import { ChildProps, QuestionCard } from '@/components/Questions/Question/helper';
import styled from 'styled-components';
import H2 from '@/components//Common/H2';
import { Color } from '@/constants/Color';

const SelectWrapper = styled.div`
  position: relative;
  &::after {
    content: 'expand_more';
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: ${Color.shadow2};
    pointer-events: none;
    font-family: 'Material Icons';
  }
`;

export default function QuestionSelect(props: ChildProps) {
  const onChange = (e: React.FormEvent<HTMLSelectElement>) =>
    props.onChange({
      ...props.answer,
      value: e.currentTarget.value,
    });
  return (
    <QuestionCard id={`question_${props.question.id}`}>
      <H2 title={`${props.order}.${props.question.title}`} />
      <SelectWrapper>
        <select value={props.answer.value} onChange={onChange} className={props.answer.value ? 'selected' : ''}>
          <option value="">選択してください</option>
          {props.question.choices &&
            props.question.choices.map(c => (
              <option key={c.value} value={c.value}>
                {c.label}
              </option>
            ))}
        </select>
      </SelectWrapper>
    </QuestionCard>
  );
}

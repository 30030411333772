import React from 'react';

import { BrowserRouter, Route, Switch, RouteProps } from 'react-router-dom';

import Application from './pages/Application';
import Lottery from './pages/Lottery';
import Resume from './pages/Resume';
import Questions from './pages/Questions';
import Completed from './pages/Completed';
import Report from './pages/Report';
import Closed from './pages/Closed';
import ErrorInvalidUser from './pages/ErrorInvalidUser';
import ScrollToTopOnPathChanges from './components/Common/ScrollToTopOnPathChanges';

const NotFound = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '80vh',
      fontFamily: 'monospace',
    }}
  >
    Not Found
  </div>
);

type AppRoute = RouteProps & {
  name: string;
};

const routes: AppRoute[] = [
  {
    name: 'application',
    exact: true,
    path: '/application',
    component: Application,
  },
  {
    name: 'lottery',
    exact: true,
    path: '/lottery/:id',
    component: Lottery,
  },
  {
    name: 'resume',
    exact: true,
    path: '/resume/:id',
    component: Resume,
  },
  {
    name: 'questions',
    exact: true,
    path: '/questions/:id',
    component: Questions,
  },
  {
    name: 'completed',
    exact: true,
    path: '/completed/:id',
    component: Completed,
  },
  {
    name: 'report',
    exact: true,
    path: '/report',
    component: Report,
  },
  {
    name: 'closed',
    exact: true,
    path: '/closed',
    component: Closed,
  },
  {
    name: 'errInvalidUser',
    exact: true,
    path: '/err/invalid-user',
    component: ErrorInvalidUser,
  },
  {
    name: 'notFound',
    exact: false,
    path: undefined,
    component: NotFound,
  },
];

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTopOnPathChanges>
        <Switch>
          {routes.map(route => (
            <Route key={route.name} {...route} />
          ))}
        </Switch>
      </ScrollToTopOnPathChanges>
    </BrowserRouter>
  );
}

import useRouter from 'use-react-router';

import { ResearcherManager } from '@/helpers/researcher';

interface Props {
  researcherId: string;
}

function isAlreadyApplied(researcherId: string): boolean {
  if (process.env.NODE_ENV === 'development') {
    return false;
  }
  const researcher = ResearcherManager.get(researcherId);
  if (!researcher || researcher.lotteryResult === undefined) {
    return false;
  }
  return researcher.lotteryResult;
}

export default function RedirectIfDuplicateApplication(props: Props) {
  const router = useRouter();
  if (isAlreadyApplied(props.researcherId)) {
    router.history.push('/err/invalid-user');
  }
  return null;
}

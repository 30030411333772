import React from 'react';

import styled from 'styled-components';
import { ChildProps, QuestionCard } from '@/components/Questions/Question/helper';
import H2 from '@/components/Common/H2';
interface ButtonProps {
  active: boolean;
}
const Button = styled.button`
  border: ${(props: ButtonProps) => (props.active ? '1px solid red' : '1px solid #000')};
  border-radius: 0;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > * {
    flex: 0 0 calc(50% - 1px);
  }
  > *:first-child {
    border-radius: 3px 0 0 3px;
  }
  > *:last-child {
    border-radius: 0 3px 3px 0;
  }
`;

export default function QuestionStaff(props: ChildProps) {
  const onClick = (value: string) =>
    props.onChange({
      ...props.answer,
      value,
    });
  const onChangeComment = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const comment = e.currentTarget.value;
    props.onChange({
      ...props.answer,
      comment,
    });
  };
  return (
    <>
      <QuestionCard id={`question_${props.question.id}`}>
        <H2 title={`${props.order}.${props.question.title}`} />
        <Wrapper>
          {props.question.choices &&
            props.question.choices.map(c => (
              <Button
                key={`${props.question.id}_${c.value}`}
                active={c.value === props.answer.value}
                onClick={() => onClick(c.value)}
                className={c.value === props.answer.value ? 'selected' : ''}
              >
                {c.label}
              </Button>
            ))}
        </Wrapper>
      </QuestionCard>
      {props.answer.value === '0' && (
        <QuestionCard>
          <H2 title="笑顔が輝いていたスタッフの氏名と具体的な理由を教えてください。※名前が分からなかった場合は、特徴（性別／おおよその年代／髪型／メガネの有無／身長○cmくらい）をご記入ください。" />
          <textarea
            onChange={onChangeComment}
            value={props.answer.comment}
            className={props.answer.comment ? 'selected' : ''}
            rows={6}
          />
        </QuestionCard>
      )}
    </>
  );
}

import React from 'react';
import useRouter from 'use-react-router';

export default function ScrollHandler() {
  const router = useRouter();
  React.useEffect(() => {
    const element = document.getElementById(router.location.hash.replace('#', ''));
    setTimeout(() => {
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element ? element.offsetTop : 0,
      });
    }, 100);
  }, [router.location]);
  return null;
}

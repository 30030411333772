import {createSlice, PayloadAction} from "redux-starter-kit";
import {ResearchReport} from "@/types/models/research_report";
import {useSelector} from "react-redux";
import {AppState} from "@/stores/index";

export interface ReportState {
    pristine: boolean;
    result: ResearchReport | false;
}

const initialState: ReportState = {
    pristine: true,
    result: false,
};

const reportModule = createSlice({
    slice: 'report',
    initialState,
    reducers: {
        initialize(state, value: PayloadAction<ResearchReport>) {
            state.pristine = false;
            state.result = value.payload;
        },
        reset(state) {
            state.pristine = true;
            state.result = false;
        },
    },
});

export const useReport = () => {
    return useSelector((state: AppState) => state.report);
};

export default reportModule;
import React from 'react';

import { QuestionCard } from '@/components/Questions/Question/helper';
import { ChildProps } from '@/components/Questions/Question/helper';
import H2 from '@/components//Common/H2';

export default function QuestionTime(props: ChildProps) {
  const onChange = (e: React.FormEvent<HTMLInputElement>) =>
    props.onChange({
      ...props.answer,
      value: e.currentTarget.value,
    });
  return (
    <QuestionCard id={`question_${props.question.id}`}>
      <H2 title={`${props.order}.${props.question.title}`} />
      <input
        type="time"
        value={props.answer.value}
        onChange={onChange}
        step={900}
        className={props.answer.value ? 'selected' : ''}
      />
    </QuestionCard>
  );
}

import React from 'react';
import useRouter from 'use-react-router';

import H1 from '@/components/Common/H1';
import GlobalStyle from '@/components/Common/GlobalStyle';
import H3 from '@/components/Common/H3';
import Space from '@/components/Common/Space';
import Terms from '@/components/Application/Terms';
import ResultAnimationWin from '@/components/Application/ResultAnimationWin';
import ResultAnimationLost from '@/components/Application/ResultAnimationLost';
import ResultOthers from '@/components/Application/ResultOthers';
import Button from '@/components/Common/Button';
import WrapperPrimary from '@/components/Common/WrapperPrimary';
import RedirectIfHasNoResearcher from '@/components/Common/RedirectIfHasNoResearcherId';
import makeLotteryHelper from '@/pages/Lottery/helper';
import RedirectIfAlreadyDone from '@/components/Common/RedirectIfAlreadyDone';

export default function Lottery() {
  const helper = makeLotteryHelper(useRouter(), React.useState<boolean>(true));
  React.useEffect(helper.initialize);
  if (helper.initializing) {
    return null;
  }
  if (!helper.restaurant) {
    return <RedirectIfHasNoResearcher researcherId={helper.researcherId} />;
  }
  return (
    <>
      <RedirectIfAlreadyDone researcherId={helper.researcherId} />
      <GlobalStyle />
      {helper.isWon && (
        <>
          <ResultAnimationWin />
          <ResultOthers>
            <WrapperPrimary>
              <Space x={2} top={5} bottom={3}>
                <Space bottom={4}>
                  <H1 title={'こちらのお店に来店し\n調査してください'} align="center" />
                  <Space top={1}>
                    <H3 title={`調査期限：${helper.dueAt}まで`} align="center" theme="danger" />
                  </Space>
                </Space>
                <Terms
                  entryTerms={[
                    { emoji: '/images/emoji_alert.png', title: '18:00〜22:00以内来店' },
                    { emoji: '/images/emoji_silhouette.png', title: '二人以上' },
                    { emoji: '/images/emoji_person.png', title: '20代〜60代' },
                  ]}
                  restaurant={`${helper.restaurant.brand_name} ${helper.restaurant.name}`}
                  address={helper.restaurant.address}
                  gmbLink={helper.restaurant.url}
                />
              </Space>
              <Space bottom={15}>
                <H1 title={`来店直前に確認ください`} align="center" />
                <Space y={1}>
                  <Button onClick={helper.goResume} label={`ガイドラインへ進む`} />
                </Space>
                <p style={{ textAlign: 'center' }}>メールからでもスタートできます</p>
              </Space>
            </WrapperPrimary>
          </ResultOthers>
        </>
      )}
      {!helper.isWon && <ResultAnimationLost />}
    </>
  );
}

import React from 'react';
import { useDispatch } from 'react-redux';
import useRouter from 'use-react-router';

import Question from '@/components/Questions/Question';
import ProgressBar from '@/components/Common/ProgressBar';
import SubmitButton from '@/components/Questions/SubmitButton';

import ScrollHandler from '@/components/Common/ScrollHandler';
import GlobalStyle from '@/components/Common/GlobalStyle';
import Space from '@/components/Common/Space';
import { useResearch } from '@/stores/research';
import makeQuestionsHelper from '@/pages/Questions/helper';
import RedirectIfHasNoResearcher from '@/components/Common/RedirectIfHasNoResearcherId';
import H3 from '@/components/Common/H3';
import RedirectIfAlreadyDone from '@/components/Common/RedirectIfAlreadyDone';

export default function Questions() {
  const helper = makeQuestionsHelper(
    useRouter(),
    useDispatch(),
    useResearch(),
    React.useState<number[]>([]),
    React.useState<boolean>(true)
  );
  React.useEffect(helper.initialize);

  if (helper.initializing || helper.questions.length === 0) {
    return <RedirectIfHasNoResearcher researcherId={helper.researcherId} />;
  }
  return (
    <>
      <RedirectIfAlreadyDone researcherId={helper.researcherId} />
      <ScrollHandler />
      <GlobalStyle />

      <ProgressBar progress={helper.progress} fixed />
      <div>
        <div
          style={{
            background: '#FFF',
            marginBottom: 8,
            paddingBottom: 16,
            textAlign: 'center',
            borderBottom: 'solid 8px #E7F0EF',
          }}
        >
          <Space top={11} bottom={6}>
            <ProgressBar progress={helper.progress} />
            <p>残り{helper.remain.toString()}問</p>
          </Space>
          <p style={{ color: '#A9A9A9' }}>変更内容は自動で保存されます</p>
        </div>
        {helper.questions.map((q, index) => (
          <Question
            key={q.id}
            order={index + 1}
            question={q}
            validAnswersState={helper.validAnswerState}
            visibleQuestionIds={helper.visibleQuestionIds}
          />
        ))}
        <Space top={4} bottom={15}>
          {helper.progress >= 100 && (
            <H3 align="center" theme="success">
              <i className="material-icons-outlined" style={{ verticalAlign: 'text-bottom' }}>
                thumb_up
              </i>{' '}
              100%！ 完了できます
            </H3>
          )}
          <SubmitButton label="覆面調査を完了する" onClick={helper.handleClickSubmit} disabled={!helper.submittable} />
        </Space>
      </div>
    </>
  );
}

import { Dispatch as HooksDispatch, SetStateAction } from 'react';
import { RouteComponentProps } from 'react-router';
import { Dispatch } from 'redux';
import { parse } from 'querystring';

import { ModalState, wrapModalState } from '@/components/Common/Modal';
import { ResearcherManager, ResearcherState } from '@/helpers/researcher';
import assignmentApplicationResultModule from '@/stores/assignment_application_result';
import { Researcher } from '@/types/models/researcher';
import { applyApplication } from '@/api/assginment_application';
import assignmentModule, { AssignmentState } from '@/stores/assignment';
import { fetchAssignment } from '@/api/assignment';
import { Restaurant } from '@/types/models/restaurant';
import { AssignmentAnswer } from '@/types/models/assignment_answer';
import { Assignment } from '@/types/models/assignment';
import {AnswerManager} from "@/helpers/answer";
import dayjs from 'dayjs';

function curriedHandleClick(
  researcher: ResearcherState | undefined,
  assignment: AssignmentState,
  answerState: AssigmentAnswerState,
  dispatch: Dispatch,
  router: RouteComponentProps
) {
  return async () => {
    if (researcher === undefined || !assignment.assignment) {
      return;
    }
    const res = await applyApplication(researcher.assignment_id, {
      researcher_id: researcher.id,
      // answers: [{ question_id: 1, value: '18-22' }, { question_id: 2, value: '2' }],
      answers: answerState.answers,
    });

    ResearcherManager.save({
      ...researcher,
      dueAt: res.due_at,
      lotteryResult: res.successful,
    });
    AnswerManager.clear();
    await dispatch(assignmentApplicationResultModule.actions.initialize(res));
    router.history.push(`/lottery/${researcher.id}`);
  };
}

function parseQueries(router: RouteComponentProps): QueryProps {
  const queries = parse(router.location.search.replace('?', ''));
  return {
    researcherId: typeof queries.id === 'string' ? queries.id : "",
    assignmentId: typeof queries.aid === 'string' ? queries.aid : "",
    researchId: typeof queries.rid === 'string' ? queries.rid : "",
  };
}

function buildAndSaveResearcher(queries: QueryProps): Researcher | false {
  if (!queries.researcherId || !queries.assignmentId || !queries.researchId) {
    return false;
  }
  return {
    id: queries.researcherId,
    assignment_id: queries.assignmentId,
    research_id: queries.researchId,
  };
}

interface QueryProps {
  researcherId: string;
  assignmentId: string;
  researchId: string;
}

export interface AssigmentAnswerState {
  allAnswered: boolean;
  answers: AssignmentAnswer[];
  add: (answer: AssignmentAnswer) => void;
  get: (questionId: number) => string;
}

function wrapAssignmentAnswers(
  assignment: AssignmentState,
  assignmentAnswers: [AssignmentAnswer[], HooksDispatch<SetStateAction<AssignmentAnswer[]>>]
): AssigmentAnswerState {
  const totalQuestions = assignment.assignment ? assignment.assignment.questions.length : 0;
  const [answers, updateAnswers] = assignmentAnswers;
  const allAnswered = totalQuestions === answers.length;
  const add = (answer: AssignmentAnswer) => {
    let added = false;
    const next = answers.map(a => {
      if (a.question_id !== answer.question_id) {
        return a;
      }
      added = true;
      return answer;
    });
    if (!added) {
      next.push(answer);
    }
    updateAnswers(next);
  };
  const get = (questionId: number) => {
    const found = answers.find(a => a.question_id === questionId);
    if (!found) {
      return '';
    }
    return found.value;
  };
  return {
    allAnswered,
    answers,
    add,
    get,
  };
}

function capacityIsOver(assignment: Assignment): boolean {
  return assignment.capacity <= assignment.number_of_assigned;
}

function isClosed(assignment: Assignment): boolean {
  const dueAt = dayjs(assignment.created_at).add(7, 'day');
  return dayjs().isAfter(dueAt.format('YYYY-MM-DD 23:59:59'));
}

interface ApplicationHelper {
  researcherId: string;
  researchId: string;
  assignmentId: string;
  restaurant: Restaurant | undefined;
  assignment: AssignmentState;
  modal: ModalState;
  handleClickApplyButton: () => void;
  initialize: () => void;
  initializing: boolean;
  answerState: AssigmentAnswerState;
  onChangeAnswer: (questionId: number, value: string) => void;
}

export default function makeApplicationHelper(
  router: RouteComponentProps,
  dispatch: Dispatch,
  assignment: AssignmentState,
  assignmentAnswers: [AssignmentAnswer[], HooksDispatch<SetStateAction<AssignmentAnswer[]>>],
  initializingState: [boolean, HooksDispatch<SetStateAction<boolean>>],
  modalState: [boolean, HooksDispatch<SetStateAction<boolean>>],
  restaurantState: [Restaurant | undefined, HooksDispatch<SetStateAction<Restaurant | undefined>>]
): ApplicationHelper {
  const queries = parseQueries(router);
  const researcher = ResearcherManager.get(queries.researcherId);
  const answerState = wrapAssignmentAnswers(assignment, assignmentAnswers);
  const modal = wrapModalState(modalState);
  const [initializing, setInitializing] = initializingState;
  const [restaurant, setRestaurant] = restaurantState;
  const handleClickApplyButton = curriedHandleClick(researcher || undefined, assignment, answerState, dispatch, router);
  const initialize = () => {
    (async () => {
      if (!assignment.pristine) {
        return;
      }
      const researcher = buildAndSaveResearcher(queries);
      if (!researcher) {
        router.history.push('/err/invalid-user');
        return;
      }
      const saved = ResearcherManager.get(researcher.id);
      if (saved && typeof saved.lotteryResult === 'boolean') {
        router.history.push(`/lottery/${saved.id}`);
        return;
      }
      const res = await fetchAssignment(researcher.id);
      if (capacityIsOver(res) || isClosed(res)) {
        router.history.push('/closed');
        return;
      }

      if (res.restaurant.name.indexOf(res.restaurant.brand_name) != -1) {
        res.restaurant.name = res.restaurant.name.replace(`${res.restaurant.brand_name} `, '');
        res.restaurant.name = res.restaurant.name.replace(`${res.restaurant.brand_name}　`, '');
        res.restaurant.name = res.restaurant.name.replace(res.restaurant.brand_name, '');
      }

      ResearcherManager.save({
        ...researcher,
        isCompleted: false,
        restaurant: res.restaurant,
      });
      dispatch(assignmentModule.actions.initialize(res));
      setRestaurant(res.restaurant);
    })();
    if (!initializing || !assignment.assignment) {
      return;
    }
    setInitializing(false);
  };
  const onChangeAnswer = (questionId: number, value: string) =>
    answerState.add({
      question_id: questionId,
      value,
    });
  return {
    researcherId: queries.researcherId,
    researchId: queries.researchId,
    assignmentId : queries.assignmentId,
    restaurant,
    assignment,
    modal,
    handleClickApplyButton,
    initialize,
    initializing,
    answerState,
    onChangeAnswer,
  };
}

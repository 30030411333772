import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';

const Wrapper = styled.div`
  width: 100%;
  background: ${Color.white};
`;

type Props = {
  children: React.ReactNode;
};

export default function WrapperWhite(props: Props) {
  return <Wrapper>{props.children}</Wrapper>;
}

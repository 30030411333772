import { combineReducers, configureStore, getDefaultMiddleware } from 'redux-starter-kit';
import assignmentModule, { AssignmentState } from './assignment';
import assignmentApplicationResultModule, { AssignmentApplicationResultState } from './assignment_application_result';
import researchModule, { ResearchState } from './research';
import reportModule, {ReportState} from "@/stores/report";

export type AppState = {
  assignment: AssignmentState;
  assignmentApplicationResult: AssignmentApplicationResultState;
  research: ResearchState;
  report: ReportState;
};

const reducer = combineReducers({
  assignment: assignmentModule.reducer,
  assignmentApplicationResult: assignmentApplicationResultModule.reducer,
  research: researchModule.reducer,
  report: reportModule.reducer,
});

export const createAppStore = () =>
  configureStore({
    reducer,
    middleware: [...getDefaultMiddleware()],
  });

import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  padding: 16px 8px 16px 16px;
  background: ${Color.shadow};
  border-radius: 16px;
  margin-top: 8px;
  > p {
    width: 30px;
    margin-right: 8px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
  }
  > div {
    width: calc(100% - 38px);
    p {
      font-size: 15px;
      font-weight: bold;
    }
    > span {
      font-size: 13px;
    }
  }
`;

type Props = {
  icon: string;
  children: React.ReactNode;
};

export default function Conponent(props: Props) {
  return (
    <Wrapper>
      <p className="material-icons-outlined">{props.icon}</p>
      <div>
        <p>{props.children}</p>
      </div>
    </Wrapper>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';
import H2 from '@/components/Common/H2';
import H3 from '@/components/Common/H3';
import Space from '@/components/Common/Space';
import List from '@/components/Common/List';
import ListItemEmoji from '@/components/Common/ListItemEmoji';

const Component = styled.div`
  background: ${Color.white};
  border-radius: 10px;
  padding: 8px;
`;

const MapBackground = styled.div`
  background: url('/images/dummy_map.png');
  background-size: cover;
  border-radius: 5px;
  width: 100%;
  min-height: 210px;
  position: relative;
`;

const RestaurantAddress = styled.a`
  width: 80%;
  height: auto;
  padding: 20px 16px 16px 16px;
  display: block;
  position: absolute;
  left: -100%;
  right: -100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  color: ${Color.secondary};
  background: ${Color.white};
  border: solid 1px ${Color.secondary};
  border-radius: 10px;
  > div {
    display: flex;
    flex-direction: row;
  }
`;

type EntryTerm = {
  emoji: string;
  title: string;
};

type Props = {
  entryTerms: EntryTerm[];
  address: string;
  gmbLink: string;
  restaurant: string;
};

export default function Terms(props: Props) {
  return (
    <Component>
      <Space top={2}>
        <H2 title="参加条件" align="center" />
      </Space>
      <Space top={2} bottom={3}>
        <List scale="large" width={220} center>
          {props.entryTerms.map(term => (
            <ListItemEmoji key={term.emoji} emoji={term.emoji}>
              <H3 title={term.title} />
            </ListItemEmoji>
          ))}
        </List>
      </Space>
      <MapBackground>
        <RestaurantAddress href={props.gmbLink} target="_blank">
          <div>
            <p style={{ width: '50px' }}>
              <i className="material-icons-outlined">pin_drop</i>
            </p>
            <div>
              <H3 title={props.restaurant} />
              <p style={{ fontSize: '12px' }}>{props.address}</p>
            </div>
          </div>
          <p style={{ fontSize: '13px', color: Color.shadow2, textAlign: 'center', paddingTop: '24px' }}>
            対象店舗を地図で見る
          </p>
        </RestaurantAddress>
      </MapBackground>
    </Component>
  );
}

import React from 'react';
import styled from 'styled-components';
import ReactSVG from 'react-svg';
import { Color } from '@/constants/Color';
import H3 from '@/components/Common/H3';
import anime from '@/components/Application/ResultAnimationWin';

const Wrapper = styled.div`
  padding: 16px;
`;

const Loading = styled.div`
  @keyframes LoadingGradient {
    0% {
      background-position: -100% -30%;
    }
    100% {
      background-position: 100% 30%;
    }
  }
  position: relative;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: ${Color.shadow2};
  svg {
    margin-top: 8px;
    fill: ${Color.secondary};
  }
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(to right, #cccccc 2%, #666666 18%, #cccccc 33%);
    background-size: 50%;
    animation: LoadingGradient 2s linear infinite;
    mix-blend-mode: overlay;
  }
`;

const Finish = styled.div`
  i {
    width: 120px;
    height: 120px;
    font-size: 32px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Color.primary};
    border-radius: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const finished = {
  opacity: [0, 1],
  translateY: [20, 0],
  duration: 300,
  easing: 'easeOutBounce',
};

type Props = {
  finish?: boolean;
};

export default function ReceiptLoading(props: Props) {
  return (
    <Wrapper>
      {props.finish ? (
        <Finish
          ref={ref => {
            anime({ ...finished, targets: ref });
          }}
        >
          <H3 title="完了しました" align="center" />
          <i className="material-icons-outlined">check</i>
        </Finish>
      ) : (
        <Loading>
          <H3 title="読み込み中" align="center" theme="shadow2" />
          <ReactSVG src="/images/receipt_loading.svg" />
        </Loading>
      )}
    </Wrapper>
  );
}

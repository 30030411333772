import React from 'react';
import styled from 'styled-components';
import anime from 'animejs';
import ReactSVG from 'react-svg';

import { Color } from '@/constants/Color';
import H1 from '@/components/Common/H1';
import H3 from '@/components/Common/H3';

const Component = styled.div`
  background: ${Color.white};
  border-radius: 10px;
  padding: 32px 8px 0;
  margin-top: 24px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  .mail {
    position: absolute;
    width: 120px;
    height: 120px;
    bottom: 24px;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const CircleFireworks = styled.div`
  width: 250px;
  height: 250px;
  padding: 28px;
  box-sizing: border-box;
  background: ${Color.primary};
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`;

const FireworkList = styled.div`
  width: 100%;
  height: 470px;
  top: 0;
  position: absolute;
  > * {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .firework {
    position: absolute;
    width: 30px;
    height: 30px;
  }
  > *:first-child .firework:nth-child(1n) {
    left: 5%;
    top: 100px;
  }
  > *:first-child .firework:nth-child(2n) {
    left: 75%;
    top: 60px;
  }
  > *:first-child .firework:nth-child(3n) {
    left: 35%;
    top: 15px;
  }
  > *:last-child .firework:nth-child(1n) {
    left: 17%;
    bottom: 150px;
  }
  > *:last-child .firework:nth-child(2n) {
    left: 67%;
    bottom: 113px;
  }
  > *:last-child .firework:nth-child(3n) {
    left: 82%;
    bottom: 156px;
  }
`;
const Headline = styled.div`
  padding-top: 104px;
  padding-bottom: 40px;
  > *:last-child {
    padding-top: 16px;
  }
`;

const animeMail = {
  opacity: [0, 1],
  translateY: [-300, 100],
  duration: 1200,
  easing: 'easeOutSine',
};
const animeFire = {
  opacity: [0, 1],
  scale: [0, 1],
  translateY: [80, 0],
  duration: 800,
};
const animeWord = {
  opacity: [0, 1],
  translateY: [40, 0],
  easing: 'easeOutBounce',
};

type Props = {};

export default function ResultAnimationWin(props: Props) {
  return (
    <Component>
      <CircleFireworks
        ref={ref => {
          anime({ ...animeFire, targets: ref, delay: 1200 });
        }}
      >
        <div
          ref={ref => {
            anime({ ...animeWord, targets: ref, delay: 1700 });
          }}
        >
          <ReactSVG src="/images/lottery_win_words_1.svg" />
        </div>
        <div
          ref={ref => {
            anime({ ...animeWord, targets: ref, delay: 1800 });
          }}
        >
          <ReactSVG src="/images/lottery_win_words_2.svg" />
        </div>
        <div
          ref={ref => {
            anime({ ...animeWord, targets: ref, delay: 1900 });
          }}
        >
          <ReactSVG src="/images/lottery_win_words_3.svg" />
        </div>
      </CircleFireworks>

      <FireworkList>
        <div
          ref={ref => {
            anime({ ...animeFire, targets: ref, delay: 2100 });
          }}
        >
          <ReactSVG className="firework" src="/images/lottery_fireworks.svg" />
          <ReactSVG className="firework" src="/images/lottery_fireworks.svg" />
          <ReactSVG className="firework" src="/images/lottery_fireworks.svg" />
        </div>
        <div
          ref={ref => {
            anime({ ...animeFire, targets: ref, delay: 2250 });
          }}
        >
          <ReactSVG className="firework" src="/images/lottery_fireworks.svg" />
          <ReactSVG className="firework" src="/images/lottery_fireworks.svg" />
          <ReactSVG className="firework" src="/images/lottery_fireworks.svg" />
        </div>
      </FireworkList>

      <div
        ref={ref => {
          anime({ ...animeMail, targets: ref, delay: 200 });
        }}
      >
        <ReactSVG className="mail" src="/images/lottery_opened.svg" />
      </div>

      <Headline
        ref={ref => {
          anime({ ...animeWord, targets: ref, delay: 2000 });
        }}
      >
        <H1 title={'おめでとうございます\n覆面調査にご参加いただけます'} align="center" />
        <H3 align="center">
          <i className="material-icons-outlined" style={{ verticalAlign: 'bottom' }}>
            mail_outline
          </i>
          <span>メールをご確認ください</span>
        </H3>
      </Headline>
    </Component>
  );
}

import React from 'react';

import Bootstrap from './Bootstrap';
import Router from './Router';

import { createAppStore } from './stores';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

const store = createAppStore();
const tagManagerArgs = {
  gtmId: 'GTM-5HK7LPN',
};
TagManager.initialize(tagManagerArgs);

export default function App() {
  return (
    <Provider store={store}>
      <Bootstrap>
        <Router />
      </Bootstrap>
    </Provider>
  );
}

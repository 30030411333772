import React from 'react';
import styled from 'styled-components';
import { Color } from '@/constants/Color';
import H1 from '@/components/Common/H1';

const Component = styled.div`
  width: 100%;
  padding: 32px 0;
  background: ${Color.primary};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const Score = styled.p`
  line-height: 1;
  font-size: 80px;
  color: ${Color.secondary};
  &::after {
    content: '点';
    font-size: 18px;
  }
`;

type Props = {
  restaurant: string;
  score: number;
};

export default function Content(props: Props) {
  return (
    <Component>
      <H1 title={props.restaurant} align="center" />
      <Score>{props.score}</Score>
    </Component>
  );
}

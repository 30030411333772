import React from 'react';
import styled from 'styled-components';
import anime from 'animejs';

const Component = styled.div``;

const animation = {
  opacity: [0, 1],
  translateY: [-5, 0],
  duration: 600,
  easing: 'easeOutSine',
};

type Props = {
  children: React.ReactNode;
};

export default function Terms(props: Props) {
  return (
    <Component
      ref={ref => {
        anime({ ...animation, targets: ref, delay: 2400 });
      }}
    >
      {props.children}
    </Component>
  );
}

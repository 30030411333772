import useRouter from 'use-react-router';

import { ResearcherManager } from '@/helpers/researcher';

interface Props {
  researcherId: string;
}

function isAlreadyDone(researcherId: string): boolean {
  const researcher = ResearcherManager.get(researcherId);
  if (!researcher || researcher.isCompleted === undefined) {
    return false;
  }
  return researcher.isCompleted;
}

export default function RedirectIfAlreadyDone(props: Props) {
  const router = useRouter();
  if (isAlreadyDone(props.researcherId)) {
    router.history.push(`/completed/${props.researcherId}`);
  }
  return null;
}

import React from 'react';
import styled from 'styled-components';

const increment = 8;

const Wrapper = styled.div`
  padding-top: ${(props: Props) => (props.top ? `calc(${increment} * ${props.top}px)` : '')};
  padding-right: ${(props: Props) => (props.right ? `calc(${increment} * ${props.right}px)` : '')};
  padding-bottom: ${(props: Props) => (props.bottom ? `calc(${increment} * ${props.bottom}px)` : '')};
  padding-left: ${(props: Props) => (props.left ? `calc(${increment} * ${props.left}px)` : '')};
  padding-left: ${(props: Props) => (props.x ? `calc(${increment} * ${props.x}px)` : '')};
  padding-right: ${(props: Props) => (props.x ? `calc(${increment} * ${props.x}px)` : '')};
  padding-top: ${(props: Props) => (props.y ? `calc(${increment} * ${props.y}px)` : '')};
  padding-bottom: ${(props: Props) => (props.y ? `calc(${increment} * ${props.y}px)` : '')};
  margin-left: ${(props: Props) => (props.center === true ? 'auto' : '')};
  margin-right: ${(props: Props) => (props.center === true ? 'auto' : '')};
`;

type Props = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  x?: number;
  y?: number;
  center?: boolean;
  children?: React.ReactNode;
};

export default function Space(props: Props) {
  return (
    <Wrapper
      top={props.top}
      right={props.right}
      bottom={props.bottom}
      left={props.left}
      x={props.x}
      y={props.y}
      center={props.center}
    >
      {props.children}
    </Wrapper>
  );
}

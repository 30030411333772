import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.ol`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  ${(props: Props) => (props.width ? `width: ${props.width}px;` : '')}
  ${(props: Props) => (props.center ? 'margin-left: auto;margin-right: auto;' : '')}
  &.small > *:not(:last-child) {
    margin-bottom: 8px;
  }
  &.large > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

type Props = {
  scale: string;
  width?: number;
  center?: boolean;
  children: object;
};

List.defaultProps = {
  scale: 'small',
};

export default function List(props: Props) {
  return (
    <Wrapper className={props.scale} scale={props.scale} width={props.width} center={props.center}>
      {props.children}
    </Wrapper>
  );
}

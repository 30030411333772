import React from 'react';
import styled from 'styled-components';
import H2 from '@/components/Common/H2';

const Wrapper = styled.div`
  h2,
  p {
    width: 100%;
    margin-bottom: 4px;
  }
  img {
    width: 100%;
    margin-bottom: 8px;
    display: block;
    height: auto;
  }
  span {
    width: 100%;
    font-size: 13px;
    display: block;
    text-align: left;
    color: #999;
  }
`;

type Props = {
  title: string;
  children: React.ReactNode;
  image?: string;
};

export default function CardDescription(props: Props) {
  return (
    <Wrapper>
      {props.image && <img src={props.image} alt={props.title} />}
      <H2 title={props.title} />
      <span>{props.children}</span>
    </Wrapper>
  );
}

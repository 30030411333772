import React from 'react';
import styled from 'styled-components';

interface Props {
  progress: number;
  fixed?: boolean;
}

const Wrapper = styled.div`
  width: 234px;
  height: 140px;
  background: #ccf4dd;
  border-radius: 18px 12px 18px 12px;
  transform: skew(-5deg);
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: ease-in-out 1s;
  ${(props: Props) =>
    props.fixed
      ? `
    width: 100%;
    height: 5px;
    border-radius: 0;
    transform: translate3d(0, 0, 0);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    display: block;
    background: none;
    div {
      display: none;
    }
  `
      : ''}
  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  &::before {
    content: '';
    display: block;
    width: ${(props: Props) => props.progress}%;
    height: 100%;
    left: 0;
    position: absolute;
    background: linear-gradient(-45deg, #00c853, #6ce7c0, #69d51d);
    background-size: 400% 400%;
    animation: Gradient 8s ease infinite;
    transition: ease-in-out 1s;
  }
  div {
    font-weight: bold;
    line-height: 1.2;
    transform: skew(5deg);
    text-align: center;
  }
  p {
    margin: 0;
  }
  .percentage {
    font-size: 28px;
    font-weight: bold;
  }
`;

export default function ProgressBar(props: Props) {
  return (
    <Wrapper progress={props.progress} fixed={props.fixed}>
      {!props.fixed && (
        <div>
          <p>完了率</p>
          <p className={'percentage'}>{props.progress}%</p>
        </div>
      )}
    </Wrapper>
  );
}
